import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { MenuItem, Stack, Typography } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// components
import MenuPopover from '../components/MenuPopover/MenuPopover';
// hook
import useResponsive from '../hooks/useResponsive';
import useLocales from '../hooks/useLocales';
// utils
import { LONG_LANG } from '../config';
//
import { IconButtonStyle, LanguageContainer } from './Styled';
import { handleOpen, handleClose, handleLanguagePopover } from './events';

// ----------------------------------------------------------------------

LanguagePopover.propTypes = {
  arrowStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  arrow: PropTypes.string,
};

function LanguagePopover({ arrowStyle, iconStyle, arrow, ...sx }) {
  const [open, setOpen] = useState(null);

  const { currentLang, changeLanguage } = useLocales();

  const isMobile = useResponsive('down', 'sm');

  const fullCurrentLang = LONG_LANG[currentLang];

  return (
    <LanguageContainer>
      <Stack
        width="100%"
        flexDirection="row"
        alignItems="center"
        gap="0.5rem"
        onClick={(event) => handleOpen(event, setOpen)}
      >
        <IconButtonStyle open={open} sx={sx}>
          <PublicIcon sx={iconStyle} />
        </IconButtonStyle>
        {!isMobile && (
          <Typography variant="textButton" color="grey.500">
            {fullCurrentLang}
          </Typography>
        )}
        <IconButtonStyle open={open} sx={sx}>
          {open ? (
            <KeyboardArrowUpIcon sx={arrowStyle} />
          ) : (
            <KeyboardArrowDownIcon sx={arrowStyle} />
          )}
        </IconButtonStyle>
      </Stack>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleClose(setOpen)}
        sx={{
          width: 100,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'textButton',
            borderRadius: 0.75,
          },
        }}
        arrow={arrow}
      >
        {Object.entries(LONG_LANG).map(([langCode, langLabel]) => (
          <MenuItem
            key={langCode}
            selected={langCode === currentLang}
            onClick={() => handleLanguagePopover(changeLanguage, langCode, handleClose, setOpen)}
          >
            {langLabel}
          </MenuItem>
        ))}
      </MenuPopover>
    </LanguageContainer>
  );
}

export default LanguagePopover;
