import { PATH_EXTERNAL, PATH_LOCAL } from '../../routes/paths';

export const menusIcon = [
  {
    name: 'myPolicies',
    subName: 'subMyPolicies',
    img: '/assets/icon_policies.svg',
    get link() {
      return PATH_LOCAL.PATH_AFTER_LOGIN;
    },
  },
  {
    name: 'myInsuranceCards',
    subName: 'subMyInsuranceCards',
    img: '/assets/icon_e_card.svg',
    get link() {
      return PATH_LOCAL.myInsurance;
    },
  },
  {
    name: 'claim',
    subName: 'subClaim',
    img: '/assets/icon_claim.svg',
    get link() {
      return PATH_LOCAL.claim;
    },
  },
  {
    name: 'downloadDocuments',
    subName: 'subDownloadDocuments',
    img: '/assets/icon_download_document.svg',
    get link() {
      return PATH_LOCAL.documentDownload;
    },
  },
  {
    name: 'hospitalNetwork',
    subName: 'subHospitalNetwork',
    img: '/assets/icon_bg_hospital_network.svg',
    get link() {
      return PATH_EXTERNAL.hospitalNetwork;
    },
  },
];

export const pageName = {
  policy: 'Policy & Coverage',
  claim: 'Claims History List > Select policy year',
};

export const navigateToPageName = {
  '/': 'home',
  '/insurance': 'My Insurance Cards',
  '/claim': 'Claim History List',
  '/download-documents': 'Download Documents',
};

export const includeReminderList = [
  PATH_LOCAL.claimHistory,
  PATH_LOCAL.claimHistoryGroup,
  PATH_LOCAL.policyCoverage,
  PATH_LOCAL.policyMember,
  PATH_LOCAL.fullYearCoverage,
];
