import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Box, Typography, Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// hooks
import useAuth from '../hooks/useAuth';
import useResponsive from '../hooks/useResponsive';
import useLocales from '../hooks/useLocales';
import useToggle from '../hooks/useToggle';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
// components
import Image from '../components/Image/Image';
import Drawer from '../components/Drawer/Drawer';
import CancelConfirmClaimDialog from '../components/UI/CancelConfirmClaimDialog';
// config
import { ALL_LANGUAGE } from '../config';
// routes
import { PATH_EXTERNAL, PATH_LOCAL } from '../routes/paths';
import {
  DrawerHeader,
  DrawerFooter,
  SidebarContent,
  MenuSidebarItemStyle,
  TranslateStack,
  TranslateTypo,
  ButtonLogout,
  ScrollSidebar,
} from './Styled';
import { StackAction } from '../components/Dialog/Styled';
import CustomDialogs from '../components/Dialog/Dialog';
//
import { getActivePolicyPath, handleLogout } from './events';
import { pageName } from './Model';
import { preventChangePage } from '../utils/claimSubmission';
import palette from '../theme/palette';

MenuSidebar.propTypes = {
  openDrawer: PropTypes.bool,
  setToggleDrawer: PropTypes.func,
  onCloseDrawer: PropTypes.func,
};

export default function MenuSidebar({ openDrawer, setToggleDrawer, onCloseDrawer }) {
  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      {isDesktop ? (
        <MenuSidebarContent />
      ) : (
        <Drawer
          mode="menuSidebar"
          width={300}
          anchor="left"
          content={<MenuSidebarContent height="100%" onCloseDrawer={onCloseDrawer} />}
          open={openDrawer}
          setOpen={setToggleDrawer}
          toggleDrawer={onCloseDrawer}
        />
      )}
    </>
  );
}

function MenuSidebarContent({ onCloseDrawer, ...other }) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { translate } = useLocales();
  const { currentLang, changeLanguage } = useLocales();
  const isDesktop = useResponsive('up', 'md');
  const [open, setOpen] = useState(false);
  const [openDialogs, setOpenDialogs] = useState(false);
  const { pathname } = useLocation();
  const moduleName = pathname.split('/')[1];

  const { toggle: selectedExternal, onOpen: onSelectExternal } = useToggle();

  const [policiesGroupMenu, setPoliciesGroupMenu] = useState([]);
  const [linkNavigate, setLinkNavigate] = useState();
  const [resultSubmit, setResultSubmit] = useState(false);
  const { submitResponse } = useSelector(({ claim }) => claim);

  useEffect(() => {
    if (submitResponse) {
      setResultSubmit(true);
    }
  }, [submitResponse]);

  const gaEventTracker = useAnalyticsEventTracker('Button click');

  const handleClickOpen = () => {
    gaEventTracker('Open logout Modal', 'Logout button');

    setOpen(true);
  };

  const handleClose = () => {
    gaEventTracker('Close logout Modal', 'Cancel logout button');

    setOpen(false);
  };

  useEffect(() => {
    const myPoliciesGroupMenu = [
      PATH_LOCAL.PATH_AFTER_LOGIN,
      PATH_LOCAL.policyCoverage,
      PATH_LOCAL.documentDownload,
      PATH_LOCAL.insuranceECard,
      PATH_LOCAL.insuranceGroup,
      PATH_LOCAL.myInsurance,
    ];

    setPoliciesGroupMenu(myPoliciesGroupMenu);
  }, [currentLang]);

  const handleNavigate = (link) => {
    gaEventTracker('Click navigate', `Navigate to ${pageName[link]} page`);

    if (pathname.includes(PATH_LOCAL.claimSubmission) && !resultSubmit) {
      setOpenDialogs(true);
      setLinkNavigate(link);
      return;
    }

    if (preventChangePage()) {
      setOpenDialogs(true);
      setLinkNavigate(link);
      return;
    }

    navigate(link);
    onCloseDrawer?.();
  };

  const handleSelectExternal = (link) => {
    gaEventTracker('Click navigate', 'Navigate to hospital network');

    onSelectExternal();
    window.open(link);
    onCloseDrawer?.();
  };

  const handleChangeLanguage = (langCode) => {
    changeLanguage(langCode);
    onCloseDrawer?.();
  };

  const handleCancel = () => {
    navigate(linkNavigate);
    setOpenDialogs(false);
    onCloseDrawer?.();
  };

  return (
    <ScrollSidebar>
      <SidebarContent role="presentation" {...other}>
        {/* Header */}
        <DrawerHeader
          onClick={() => handleNavigate(PATH_LOCAL.profile)}
          active={!selectedExternal && pathname === PATH_LOCAL.profile}
        >
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Typography
              variant="h6Reg"
              color="primary.contrastText"
              flexBasis="100%"
              marginLeft="5px"
            >
              {translate('sidebar.hello')}
            </Typography>
            <Typography
              variant="h5Bold"
              color="primary.contrastText"
              flexBasis="100%"
              marginLeft="5px"
            >
              {user?.firstname} {user?.lastname}
            </Typography>
          </Stack>
          <Image src="/assets/icon_user.svg" />
        </DrawerHeader>

        {/* Body */}
        <Box>
          {/* POLICY */}
          <MenuSidebarItemStyle
            direction="row"
            spacing={2.25}
            onClick={() => handleNavigate(PATH_LOCAL.PATH_AFTER_LOGIN)}
            active={!selectedExternal && getActivePolicyPath(policiesGroupMenu, pathname)}
          >
            <Image src="/assets/icon_sidebar_policies.svg" />
            <Typography color={theme.palette.grey[0]} variant="emmaReg19_24">
              {translate('sidebar.myPolicies')}
            </Typography>
          </MenuSidebarItemStyle>

          {/* CLAIM */}
          <MenuSidebarItemStyle
            direction="row"
            spacing={2.25}
            onClick={() => handleNavigate(PATH_LOCAL.claim)}
            active={!selectedExternal && moduleName && PATH_LOCAL.claim.includes(moduleName)}
          >
            <Image src="/assets/icon_sidebar_claim.svg" />
            <Typography variant="emmaReg19_24" color="grey.0">
              {translate('sidebar.claimInquiry')}
            </Typography>
          </MenuSidebarItemStyle>

          <MenuSidebarItemStyle
            mt={'.25rem'}
            direction="row"
            spacing={2.25}
            onClick={() => handleSelectExternal(PATH_EXTERNAL.hospitalNetwork)}
          >
            <Image src="/assets/icon_sidebar_hospital.svg" />
            <Typography color={theme.palette.grey[0]} variant="emmaReg19_24">
              {translate('sidebar.hospitalNetwork')}
            </Typography>
          </MenuSidebarItemStyle>

          {!isDesktop && (
            <MenuSidebarItemStyle
              ownerState={{
                ':hover': {
                  backgroundColor: 'none !important',
                  cursor: 'default',
                },
              }}
              justifyContent="center"
            >
              <TranslateStack direction="row" spacing={3}>
                {ALL_LANGUAGE.map((langCode) => (
                  <TranslateTypo
                    key={langCode}
                    onClick={() => handleChangeLanguage(langCode)}
                    variant="h5"
                    active={currentLang === langCode}
                  >
                    {langCode.toUpperCase()}
                  </TranslateTypo>
                ))}
              </TranslateStack>
            </MenuSidebarItemStyle>
          )}
        </Box>

        {/* Footer */}
        <DrawerFooter>
          <Image src="/assets/icon_emma_by_axa.png" width="140px" />
          <Button
            variant="outlinedWhite"
            sx={{ mt: '24px', width: '223px', height: '44px', borderRadius: '27px' }}
            onClick={handleClickOpen}
          >
            <Typography variant="h5">{translate('sidebar.logout')}</Typography>
          </Button>
        </DrawerFooter>
      </SidebarContent>

      {/* Dialogs confirm logout */}
      <CustomDialogs
        open={open}
        handleClose={handleClose}
        mode="nodivide"
        title={
          <Stack alignItems={'center'} paddingY={{ xs: 0, md: 4 }}>
            <Typography variant="h1Header" color="primary.text">
              {translate('sidebar.logout')}
            </Typography>
          </Stack>
        }
        content={
          <Stack alignItems={'center'} paddingBottom={{ xs: 3, md: 0 }}>
            <Typography variant="h2Med" sx={{ fontSize: 20 }} color="primary.text">
              {translate('sidebar.logoutDes')}
            </Typography>
          </Stack>
        }
        action={
          <StackAction
            direction={{ xs: 'column-reverse', md: 'row' }}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={1}
            paddingX={{ xs: 9, md: 6 }}
            paddingBottom={3}
          >
            <ButtonLogout
              variant="outlinedEmma"
              sx={{ border: `0.5px solid ${palette.emma.$494DF4}` }}
              onClick={handleClose}
            >
              {translate('sidebar.cancel')}
            </ButtonLogout>
            <ButtonLogout variant="primaryEmma" onClick={() => handleLogout(logout)}>
              {translate('sidebar.ok')}
            </ButtonLogout>
          </StackAction>
        }
        maxWidth={'xs'}
      />

      {/* Dialogs Prevent Change page */}
      <CancelConfirmClaimDialog
        openDialogs={openDialogs}
        setOpenDialogs={setOpenDialogs}
        handleCancel={handleCancel}
      />
    </ScrollSidebar>
  );
}

MenuSidebarContent.propTypes = {
  onCloseDrawer: PropTypes.func,
};
