import { PATH_LOCAL } from '../routes/paths';

// STATUS
const ACTIVE = 'active';
const INACTIVE = 'inactive';
const NO = 'N';
const YES_FLAG = '1';

// REQUEST
const STATUS_500 = 500;
const STATUS_503 = 503;
const AUTH = '/auth';
const CLAIMSUBMIT = '/claim-submission';
const LOADING = 'loading';
const POLICY = '/policy';

// FAMILY
const FAMILY_OWNER = '00';

// ERROR
const ERR_NETWORK = 'ERR_NETWORK';
const errorPageList = [
  PATH_LOCAL.page404,
  PATH_LOCAL.page500,
  PATH_LOCAL.page503,
  PATH_LOCAL.pageNoInternet,
];
const notDisplayPageList = [
  PATH_LOCAL.page500,
  PATH_LOCAL.page503,
  PATH_LOCAL.pageNoInternet,
  PATH_LOCAL.termsAndConditions,
];

export {
  ACTIVE,
  INACTIVE,
  NO,
  YES_FLAG,
  STATUS_500,
  STATUS_503,
  AUTH,
  FAMILY_OWNER,
  ERR_NETWORK,
  errorPageList,
  notDisplayPageList,
  CLAIMSUBMIT,
  LOADING,
  POLICY,
};
