import { combineReducers } from 'redux';
// slices
import claimReducer from './slices/claim';
import policyReducer from './slices/policy';
import customHeaderReducer from './slices/customHeader';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  claim: claimReducer,
  policy: policyReducer,
  customHeader: customHeaderReducer,
});

export default rootReducer;
