import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
// @mui
import { CardHeader, Typography, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
// redux
import { useSelector, useDispatch } from '../../redux/store';
import { setCustomHeader } from '../../redux/slices/customHeader';
//
import { goBack } from './events';
import { HeaderBanner, HeaderContent } from './Styled';
import Image from '../Image/Image';
import { PATH_LOCAL } from '../../routes/paths';
import CancelConfirmClaimDialog from '../UI/CancelConfirmClaimDialog';

MobileHeaderMenu.propTypes = {
  name: PropTypes.string,
  onOpen: PropTypes.func,
};

export default function MobileHeaderMenu({ name, onOpen }) {
  const navigate = useNavigate();
  const [openDialogs, setOpenDialogs] = useState(false);

  const dispatch = useDispatch();

  const [resultSubmit, setResultSubmit] = useState(false);
  const { submitResponse } = useSelector(({ claim }) => claim);

  const theme = useTheme();
  const { pathname } = useLocation();

  const onClickClose = () => {
    if (resultSubmit) {
      navigate(PATH_LOCAL.PATH_AFTER_LOGIN);
    }
    setOpenDialogs(true);
  };

  const handleCancel = () => {
    setOpenDialogs(false);
    dispatch(setCustomHeader(''));
    goBack(navigate);
  };

  useEffect(() => {
    if (submitResponse) {
      setResultSubmit(true);
    }
  }, [submitResponse]);

  return (
    <HeaderBanner>
      <HeaderContent>
        {pathname.includes(PATH_LOCAL.claimSubmission) ? (
          <IconButton onClick={onClickClose} sx={{ padding: 0 }}>
            <Image src="/assets/icon-close-reg.svg" />
          </IconButton>
        ) : (
          <IconButton onClick={() => goBack(navigate)} sx={{ padding: 0 }}>
            <ArrowBackIosNewIcon fontSize="16" style={{ color: theme.palette.grey[0] }} />
          </IconButton>
        )}
        <CardHeader
          sx={{
            padding: 2,
            '&.MuiCardHeader-root': { overflow: 'hidden' },
            '& .MuiCardHeader-content': { overflow: 'hidden' },
          }}
          title={
            <Typography
              variant="h5Bold"
              width="100%"
              noWrap
              gutterBottom
              display="block"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              mb={0}
            >
              {name}
            </Typography>
          }
        />
        <IconButton onClick={onOpen} sx={{ padding: 0 }}>
          <MenuIcon style={{ color: theme.palette.grey[0] }} />
        </IconButton>
      </HeaderContent>

      {/* Dialogs Prevent Change page */}
      <CancelConfirmClaimDialog
        openDialogs={openDialogs}
        setOpenDialogs={setOpenDialogs}
        handleCancel={handleCancel}
      />
    </HeaderBanner>
  );
}
