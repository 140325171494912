import ReactGA from 'react-ga4';
import { isEmpty } from 'lodash';
// routes
import { PATH_API, PATH_AUTH, PATH_LOCAL } from '../routes/paths';
//
import { errorPageList } from '../constants/general';
// utils
import axios from '../utils/axios';
import mappingLanguage from '../utils/mappingLanguage';

export const handleOpen = (event, setOpen) => {
  ReactGA.event({
    category: 'Button click',
    action: 'Open popover language',
    label: 'Language button',
  });

  setOpen(event.currentTarget);
};
export const handleClose = (setOpen) => {
  ReactGA.event({
    category: 'Button click',
    action: 'Close popover language',
    label: 'Language button',
  });

  setOpen(null);
};

export const handleLanguagePopover = (changeLanguage, langCode, handleClosePopover, setOpen) => {
  changeLanguage(langCode);
  handleClosePopover(setOpen);
};

function handleSwapObject(obj) {
  const swappedObject = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (!swappedObject[value]) {
      swappedObject[value] = key;
    }
  });

  return swappedObject;
}

function handleDynamicProductHeader(productHeaderEN, policy) {
  if (!policy) {
    return null;
  }

  return mappingLanguage(policy, 'productHeader') || handleSwapObject(policy)[productHeaderEN];
}

export function mappingHeaderTitle(paths, currentPath, dynamicParams, policy) {
  // * Handle the dynamic parameters => /:
  if (!isEmpty(dynamicParams)) {
    currentPath = `${currentPath}/`;

    Object.values(dynamicParams).forEach((value) => {
      if (currentPath.includes(`/${value}/`)) {
        currentPath = currentPath.replace(`/${value}`, '');
      }
    });

    const lastIndex = currentPath.lastIndexOf('/');

    if (lastIndex !== -1) {
      currentPath =
        currentPath.substring(0, lastIndex) + currentPath.substring(lastIndex + currentPath.length);
    }
  }

  return isEmpty(dynamicParams.productHeader)
    ? handleSwapObject(paths)[currentPath]
    : handleDynamicProductHeader(dynamicParams?.productHeader, policy);
}

export const handleFooterItem = (pathExternal) => [
  { icon: '/assets/icon_facebook_logo.svg', to: pathExternal.social.facebook },
  { icon: '/assets/icon_line_logo.svg', to: pathExternal.social.line },
  { icon: '/assets/icon_twitter_logo.svg', to: pathExternal.social.twitter },
  { icon: '/assets/icon_linkedin_logo.svg', to: pathExternal.social.linkedin },
  { icon: '/assets/icon_youtube_logo.svg', to: pathExternal.social.youtube },
  { icon: '/assets/icon_ig_logo.svg', to: pathExternal.social.ig },
];

export function getActivePolicyPath(includingPath, currentPath = '') {
  return (
    currentPath === PATH_LOCAL.PATH_AFTER_LOGIN ||
    includingPath.some(
      (path) => currentPath.startsWith(path) && path !== PATH_LOCAL.PATH_AFTER_LOGIN
    )
  );
}

export async function getToken() {
  ReactGA.event({
    category: 'Button click',
    action: 'Get token',
    label: 'Follow us button',
  });

  const { data: auth0Token } = await axios.request({
    method: 'post',
    url: PATH_API.auth.getAuth0Token,
    headers: {
      'Content-Type': '',
    },
  });

  navigator.clipboard.writeText(auth0Token.accessToken);
}

export function handleLogout(logout) {
  ReactGA.event({
    category: 'Button click',
    action: 'Logout',
    label: 'Logout button',
  });

  logout();
}

export function handleOpenDrawer(onOpenDrawer) {
  ReactGA.event({
    category: 'Button click',
    action: 'Sidebar menu',
    label: 'Hamburger button',
  });

  onOpenDrawer();
}

export function handleCheckErrorPage(pathname) {
  return errorPageList.includes(pathname);
}

export function renderRedirectController(isDesktop, currentPath, user) {
  const disablePath = [PATH_LOCAL.claimSubmission, PATH_LOCAL.PATH_AFTER_LOGIN, PATH_AUTH.login];

  return (
    !user ||
    !isDesktop ||
    handleCheckErrorPage(currentPath) ||
    disablePath.some((path) => currentPath.endsWith(path))
  );
}

export function handleNameHeader(dynamicParams, pageTitle, translate) {
  return !dynamicParams?.productHeader ? translate(`header.${pageTitle}`) : pageTitle;
}
