export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1, 2),
          },
        },
        containedPrimary: {
          border: `0.5px solid ${theme.palette.grey[400]}`,
          '&:hover': {
            border: `0.5px solid ${theme.palette.grey[500]}`,
          },
          '&.active': {
            border: `0.5px solid ${theme.palette.emma.$494DF4}`,
          },
          '&.disabled': {
            border: `0.5px solid ${theme.palette.grey[400]}`,
          },
        },
      },
    },
  };
}
