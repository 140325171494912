import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,
}));

export const StyledCircularProgress = styled(CircularProgress)({
  '&.MuiCircularProgress-colorPrimary': {
    color: 'transparent',
  },

  '& .MuiCircularProgress-circle': {
    stroke: 'url(#linearColors)',
    opacity: 0.8,
  },
});
