import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_LOCAL } from '../routes/paths';

// ----------------------------------------------------------------------

InternetGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function InternetGuard({ children }) {
  const [isOnline, setIsOnline] = useState(true);
  //   On initialization set the isOnline state.
  useEffect(() => {
    setIsOnline(navigator.onLine);
  }, []);
  // event listeners to update the state
  window.addEventListener('online', () => {
    setIsOnline(true);
  });
  window.addEventListener('offline', () => {
    setIsOnline(false);
  });

  // * if user is online, return the child component else return a custom component
  if (isOnline) {
    return <>{children}</>;
  }

  return <Navigate to={PATH_LOCAL.pageNoInternet} replace />;
}
