import { PATH_LOCAL } from '../routes/paths';

export const pageName = {
  '/profile': 'Personal Information',
  '/': 'home',
  '/claim': 'Claim History List',
};

export function handleEnableScroll(pathname, productHeader) {
  return PATH_LOCAL.claimSubmission === pathname || !!productHeader;
}
