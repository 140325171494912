import axios from './axios';

// ----------------------------------------------------------------------

const clearSession = () => {
  delete axios.defaults.headers.common.Authorization;
  sessionStorage.clear();
};

const setSession = (token, name = 'idToken') => {
  if (token) {
    sessionStorage.setItem(name, token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    clearSession();
  }
};

export { setSession, clearSession };
