// i18n
import './locales/i18n';

import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
// redux
import { store } from './redux/store';
// contexts
import { AuthProvider } from './contexts/AuthContext';
// config
import { GA_MEASUREMENT_ID } from './config';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize(GA_MEASUREMENT_ID);

root.render(
  <ReduxProvider store={store}>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </ReduxProvider>
);

// register the service workers
// to work offline and load faster
// * Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
