import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// config
import { DEFAULT_LANGUAGE } from '../config';
//
import { thLang } from './th';
import { enLang } from './en';

const resources = {
  en: {
    translations: enLang,
  },
  th: {
    translations: thLang,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
