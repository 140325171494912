import ReactGA from 'react-ga4';
// utils
import { convertDate, convertMonthByLang, convertYearByLang } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import { convertTitleToParamCase } from '../../utils/changeCase';
// routes
import { PATH_LOCAL } from '../../routes/paths';
//
import { INACTIVE } from '../../constants/general';
import { claimHistoryStatusLabel, claimPendingStatus, noDisplayPendingStatusLabel } from './Model';

export function groupClaimInactivePolicy(listItem) {
  const filterPolicy = (item, filterKey, filterValue) => item[filterKey] === filterValue;

  const mappingStatus = (item) => ({ [item.policyStatus]: [{ ...item }] });

  const filteredInactiveItem = listItem.filter((item) =>
    filterPolicy(item, 'policyStatus', INACTIVE)
  );

  return filteredInactiveItem.map(mappingStatus);
}

export const getByMonth = (claimsDetail) => {
  if (!claimsDetail) {
    return claimsDetail;
  }

  function convertMonthYear(claim) {
    return `${convertMonthByLang(claim.admitDate)} ${convertYearByLang(claim.admitDate)}`;
  }

  const result = {};
  claimsDetail.forEach((claim) => {
    if (!result[convertMonthYear(claim)]) {
      result[convertMonthYear(claim)] = [{ ...claim }];
    } else {
      result[convertMonthYear(claim)].push(claim);
    }
  });

  return result;
};

export function handleRenderClaimHistoryLabel(claim) {
  if (claimPendingStatus.includes(claim.claimStatusCode) && +claim.charge !== 0) {
    noDisplayPendingStatusLabel.splice(4, 1);
  }

  return !claimPendingStatus.includes(claim.claimStatusCode)
    ? claimHistoryStatusLabel
    : claimHistoryStatusLabel.filter((label) => !noDisplayPendingStatusLabel.includes(label));
}

export const groupItemStatusDetail = (claim, label, translate) => {
  const twoDecimal = /^\d+\.\d{2}$/;

  const filteredItem = {};
  Object.keys(claim).forEach((key) => {
    const value = claim[key];

    if (label.includes(key)) {
      if (key.endsWith('Date')) {
        filteredItem[key] = convertDate(value, false, true);
      } else if (twoDecimal.test(value)) {
        if (+value !== 0) {
          filteredItem[key] = `${fCurrency(+value)} ${translate('THB')}`;
        }
      } else {
        filteredItem[key] = value;
      }
    }
  });

  return filteredItem;
};

export function handleSortItem(items, sortedBy) {
  const sortedItem = {};
  sortedBy.forEach((label) => {
    if (items[label]) {
      sortedItem[label] = items[label];
    }
  });

  return sortedItem;
}

export function handlePathHistoryGroup(policy) {
  ReactGA.event({
    category: 'Button click',
    action: 'Navigate to Claims History (All case) page',
    label: 'Navigate button',
  });

  return `${PATH_LOCAL.claimHistoryGroup}/${convertTitleToParamCase(
    policy.productHeaderEN,
    true
  )}/${policy.policyNo}/${policy.memberNo}/${policy.dpntNo}?policy_status=${policy.policyStatus}`;
}

export function navigateToClaimHistory(
  productHeader,
  item,
  policyNo,
  memberNo,
  dpntNo,
  policyStatus,
  selectedFamily
) {
  ReactGA.event({
    category: 'Button click',
    action: 'Navigate to Claims History (case: 1) page',
    label: 'Claim items button',
  });

  return `${PATH_LOCAL.claimHistory}/${productHeader}/${item.notifyNo}/${policyNo}/${memberNo}/${dpntNo}?policy_status=${policyStatus}&f=${selectedFamily}`;
}

export function isValidEmail(email) {
  // format email RFC 5322
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
}
