import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { PATH_LOCAL } from '../routes/paths';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <RouterLink to={PATH_LOCAL.PATH_AFTER_LOGIN}>
      <Box component="img" src="/assets/logo_th.svg" width="85px" height="42px" {...sx} />
    </RouterLink>
  );
}
