import PropTypes from 'prop-types';
// @mui
import { Popover } from '@mui/material';
import { ArrowStyle } from './Styled';

// ----------------------------------------------------------------------

MenuPopover.propTypes = {
  children: PropTypes.node,
  arrow: PropTypes.oneOf([
    'top-left',
    'top-center',
    'top-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
    'left-top',
    'left-center',
    'left-bottom',
    'right-top',
    'right-center',
    'right-bottom',
  ]),
  disabledArrow: PropTypes.bool,
  sx: PropTypes.object,
};

export default function MenuPopover({
  children,
  arrow = 'top-right',
  disabledArrow,
  sx,
  ...other
}) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 200,
          overflow: 'inherit',
          ...(arrow === 'bottom-center' && {
            marginTop: -5,
          }),
          ...sx,
        },
      }}
      {...other}
    >
      {!disabledArrow && <ArrowStyle arrow={arrow} />}
      {children}
    </Popover>
  );
}
