import {
  Box,
  Button,
  Divider,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomContainer = styled(Stack)({
  width: '100%',
  paddingBottom: '5px',
});

export const HistoryCard = styled(Stack)(({ theme, sx }) => ({
  flexFlow: 'row wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '72px',
  minWidth: '263px',
  borderRadius: '8px',
  boxShadow: theme.customShadows.cardMain,
  marginBottom: '12px',
  padding: '0.75rem 1.5rem 0.75rem 1rem',
  [theme.breakpoints.up('md')]: {
    ':hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.action.hover,
    },
  },
  ...sx,
}));

export const PolicyCard = styled(Stack)(({ theme }) => ({
  flexFlow: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '263.33px',
  height: '100%',
  minHeight: '4.5rem',
  gap: '1rem',
  padding: '0 1.5rem 0 0.4rem',
  borderRadius: '8px',
  boxShadow: theme.customShadows.cardMain,
  marginBottom: '12px',
  [theme.breakpoints.up('md')]: {
    ':hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  },
}));

export const IconStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexFlow: 'row wrap',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '1.5rem',
  [theme.breakpoints.up('mobileM')]: {
    justifyContent: 'space-between',
    gap: '0.15rem',
  },
  [theme.breakpoints.up('mobileL')]: {
    gap: '0.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    gap: '5rem',
  },
  [theme.breakpoints.up('md')]: {
    flexFlow: 'row wrap',
    justifyContent: 'start',
    gap: '2.5rem',
    marginTop: '1.5rem !important',
  },
}));

export const BoxContentStyle = styled(Stack)({
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',
  ':hover': {
    transform: 'scale(1.1)',
  },
});

export const ContainerScrollDark = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  bottom: 0,
  padding: 0,
  [theme.breakpoints.up('md')]: {
    height: 'calc(100vh - 163px)',
    width: '100vw',
    position: 'fixed',
    top: '80px',
  },
}));

export const DarkBackground = styled(Stack)(() => ({
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  backdropFilter: 'blur(2.5px)',
  transform: 'matrix(1, 0, 0, -1, 0, 0)',
  transition: 'all .3s',
}));

export const FamilyItemStyle = styled(Box, {
  shouldForwardProp: (props) => props !== 'offsetLeft',
})(({ theme, offsetLeft }) => ({
  maxWidth: `calc(100vw - ${offsetLeft}px)`,
  width: '100%',
  padding: '0 1.5rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 3rem',
    ':hover': {
      backgroundColor: `${theme.palette.emma.$B5D0EE}26`,
    },
  },
}));

export const DarkStack = styled(Stack)({
  height: '55px',
  width: '100%',
  cursor: 'pointer',
});

export const DarkStackContent = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'containerWidth',
})(({ theme, containerWidth }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    maxWidth: `calc(${containerWidth} / 16 * 1rem)`,
    paddingRight: '4.25rem',
  },
}));

export const StackBackgroundWhite = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[0],
  paddingBottom: 108,
  [theme.breakpoints.up('md')]: {
    paddingBottom: 0,
  },
}));

export const ToTopButton = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: -25,
  right: '6.25%',
  width: 65,
  height: 65,
  background: theme.palette.emma.$494DF4,
  border: `3px solid ${theme.palette.grey[0]}`,
  color: theme.palette.grey[0],
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25098)',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  padding: 6,
  zIndex: 999,
  ':hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: {
    height: '3.5rem',
    width: '3.5rem',
    top: '-28px',
  },
}));

export const ReminderContainer = styled(Box, {
  shouldForwardProp: (props) => props !== 'disabledSticky',
})(({ disabledSticky }) => {
  const positionStyle = !disabledSticky
    ? { position: 'sticky', bottom: 0 }
    : { position: 'default' };

  return { width: '100%', marginTop: 8, zIndex: 9999, ...positionStyle };
});

export const DividerStyle = styled(Divider)(({ theme }) => ({
  width: '100vw',
  height: '1.25rem',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.customShadows.dialogAction,
  backdropFilter: 'blur(15px)',
  border: 'none',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    position: 'fixed',
  },
}));

export const FooterStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  bottom: 0,
  zIndex: 99,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.customShadows.dialogAction,
  backdropFilter: 'blur(15px)',
  cursor: 'pointer',
}));

export const FooterStackContent = styled(Stack)({
  height: '100%',
  width: '100%',
  alignItems: 'center',
  padding: '0 1.25rem',
  zIndex: 998,
});

export const ImageButton = styled('img')(({ theme }) => ({
  width: '65px',
  height: '65px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    width: '80px',
    height: '80px',
  },
}));

export const ProtectionServices = styled(Stack)(({ theme }) => ({
  padding: '1.5rem 0',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    padding: '2rem',
    paddingLeft: 0,
  },
}));

export const LinkExternalStyle = styled(Link)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  lineHeight: 0,
  [theme.breakpoints.up('md')]: {
    width: '80px',
    maxHeight: '122px',
  },
}));

export const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 265,
    backgroundColor: '#fff',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25098)',
  },
  [`& .MuiTooltip-tooltipPlacementTop`]: {
    marginBottom: '5px !important',
    padding: '10px',
  },
});

export const StackUnderline = styled(Stack)(({ theme }) => ({
  padding: '4px 0 8px 0',
  borderBottom: `solid 1px ${theme.palette.grey[300]}`,
  fontSize: '19px',
  fontWeight: '400',
  lineHeight: '19px',
  color: theme.palette.grey[850],
}));

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'mode',
})(({ theme, mode }) => ({
  pointerEvents: 'auto',
  ...(mode === 'disable' && {
    pointerEvents: 'none',
    backgroundColor: theme.palette.grey[300],
    height: '100%',
    borderRadius: 8,
  }),
  [`input`]: {
    fontSize: '21px',
    fontWeight: '400',
    lineHeight: '21px',
    color: theme.palette.grey[850],
  },
  height: '46px',
}));

export const CustomSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'mode',
})(({ theme, mode }) => ({
  height: '44px',
  fontSize: '21px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 8,
  },
  '&& .MuiSvgIcon-root': {
    fill: `${theme.palette.emma.$494DF4} !important`,
  },
  ...(mode === 'disable' && {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[300],
    borderRadius: 8,
    '&& .MuiSvgIcon-root': {
      fill: `${theme.palette.grey[500]} !important`,
    },
    pointerEvents: 'none',
  }),
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  color: '#000',
  fontSize: '21px',
  ':hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

export const RedirectControllerStyle = styled(Box, {
  shouldForwardProp: (props) => props !== 'expanded',
})(({ expanded }) => ({
  width: '100%',
  height: 44,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  margin: expanded ? '3rem 0 9.3rem' : '3rem 0 4rem',
}));

export const ButtonStyle = styled(Button)(({ theme }) => ({
  width: 223,
  height: 44,
  fontFamily: theme.typography.fontFamilyTertiary,
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '23.97px',
  textAlign: 'center',
}));

export const ValueDate = styled(Typography)(() => ({
  position: 'absolute',
  fontSize: '21px',
  top: '8px',
  left: '16px',
}));

export const ButtonAccept = styled(Button)(() => ({
  width: '223px',
  height: '44px',
  fontSize: '20px',
}));
