import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, Stack } from '@mui/material';

export const CustomDialogTitle = styled(DialogTitle)(({ theme, sx }) => ({
  margin: '0 0 8px',
  padding: 0,
  textAlign: 'center',
  color: theme.palette.primary.main,
  fontFamily: theme.typography.fontFamilyTertiary,
  fontWeight: 700,
  ...sx,
}));

export const CustomDialogContent = styled(DialogContent)(({ theme, sx }) => ({
  margin: '0 0 24px',
  padding: '0 !important',
  textAlign: 'center',
  color: theme.palette.primary.text,
  fontWeight: 400,
  ...sx,
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    borderRadius: 0,
    padding: 60,
  },
}));

export const ImageTerms = styled('img')(() => ({
  width: '100%',
}));

export const StackAction = styled(Stack)(({ mode }) => ({
  width: '100%',
  height: '106px',
  ...(mode === 'boxShadow' && {
    boxShadow: '0px -8px 8px rgba(0, 0, 0, 0.15)',
  }),
}));

export const DialogTerms = styled(Dialog)(({ maxheight, custommaxwidth }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    maxHeight: `${maxheight}`,
    maxWidth: `${custommaxwidth}`,
    margin: '48px 16px',
  },
  zIndex: 9999,
}));
