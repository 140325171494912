import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
// routes
import { PATH_LOCAL } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (isAuthenticated && user) {
    return <Navigate to={PATH_LOCAL.PATH_AFTER_LOGIN} replace />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
