import ReactGA from 'react-ga4';
import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layout
import MainLayout from '../layouts';
// components
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import InternetGuard from '../guards/InternetGuard';
// pages
import PageNoInternet from '../pages/others/PageNoInternet';

const Loadable = (Component) => (props) =>
  (
    <InternetGuard>
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    </InternetGuard>
  );

export default function Router() {
  const { pathname, search } = window.location;

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `${pathname}${search}` });
  }, [pathname, search]);

  return useRoutes([
    {
      path: 'auth',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '503', element: <Page503 /> },
        { path: '404', element: <Page404 /> },
        { path: 'no-internet', element: <PageNoInternet /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        {
          path: 'claim',
          children: [
            { element: <Claim />, index: true },
            { path: 'policy', element: <ClaimPolicy /> },
            {
              path: 'history-group/:productHeader/:policyNo/:memberNo/:dpntNo',
              element: <ClaimHistoryGroup />,
            },
            {
              path: 'history/:productHeader/:notifyNo/:policyNo/:memberNo/:dpntNo',
              element: <ClaimHistory />,
            },
          ],
        },
        { path: 'claim-submission', element: <ClaimSubmission /> },
        { path: 'profile', element: <Profile /> },
        {
          path: 'policy',
          children: [
            { path: ':productHeader/:params', element: <PolicyCoverage /> },
            {
              path: ':productHeader/:params/full-year-coverage',
              element: <FullYearCoverage />,
            },
            { path: 'all-policy-past', element: <AllPolicyPast /> },
            { path: 'select-policy-year/:policyNo', element: <SelectPolicyYear /> },
            { path: ':productHeader/:params/member/:memberId', element: <PolicyMember /> },
          ],
        },
        {
          path: 'insurance',
          children: [
            { element: <Insurance />, index: true },
            {
              path: 'group/:productHeader/:policyNo/:memberNo/:dpntNo/:policyStatus',
              element: <InsuranceGroup />,
            },
            { path: 'detail/:productHeader/:policyNo', element: <InsuranceDetail /> },
          ],
        },
        { path: 'download-documents', element: <DownloadDocuments /> },
      ],
    },
    { path: 'terms-and-conditions', element: <TermsAndConditions /> },
    { path: '*', element: <Navigate to="404" replace /> },
  ]);
}

// * AUTH
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// * POLICY
const HomePage = Loadable(lazy(() => import('../pages/home')));
const Profile = Loadable(lazy(() => import('../pages/profile/Profile')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/termsc/index')));
const PolicyCoverage = Loadable(lazy(() => import('../pages/policy/PolicyCoverage')));
const PolicyMember = Loadable(lazy(() => import('../pages/policy/family/index')));
const FullYearCoverage = Loadable(
  lazy(() => import('../pages/policy/full-year-coverage/FullYearCoverage'))
);
const AllPolicyPast = Loadable(lazy(() => import('../pages/home/AllPolicyPast')));
const SelectPolicyYear = Loadable(lazy(() => import('../pages/home/SelectPolicyYear')));

// * CLAIM
const Claim = Loadable(lazy(() => import('../pages/claim/Claim')));
const ClaimPolicy = Loadable(lazy(() => import('../pages/claim/ClaimPolicy')));
const ClaimSubmission = Loadable(lazy(() => import('../pages/claim/claimsubmission')));
const ClaimHistoryGroup = Loadable(lazy(() => import('../pages/claim/ClaimHistoryGroup')));
const ClaimHistory = Loadable(lazy(() => import('../pages/claim/ClaimHistory')));

// * INSURANCE
const Insurance = Loadable(lazy(() => import('../pages/insurance/InsuranceList')));
const InsuranceGroup = Loadable(lazy(() => import('../pages/insurance/InsuranceGroup')));
const InsuranceDetail = Loadable(lazy(() => import('../pages/insurance/InsuranceDetail')));

// * DOWNLOAD DOCUMENTS
const DownloadDocuments = Loadable(
  lazy(() => import('../pages/download-document/DownloadDocuments'))
);

// * OTHERS
const Page404 = Loadable(lazy(() => import('../pages/others/Page404')));
const Page503 = Loadable(lazy(() => import('../pages/others/Page503')));
const Page500 = Loadable(lazy(() => import('../pages/others/Page500')));
