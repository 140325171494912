// ----------------------------------------------------------------------

import palette from '../palette';

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '27px',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 50,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        primary: {
          color: theme.palette.grey[0],
          backgroundColor: palette.primary.main,
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
        },
        primaryEmma: {
          color: theme.palette.grey[0],
          backgroundColor: palette.emma.$494DF4,
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
          '&:active': {
            backgroundColor: palette.primary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.grey[400],
            color: palette.grey[0],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        // outlined
        outlinedInherit: {
          border: `0.5px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedSecondary: {
          color: theme.palette.secondary.main,
          border: `0.5px solid ${theme.palette.secondary.main}`,
        },
        outlinedWhite: {
          color: theme.palette.grey[0],
          border: `1px solid ${theme.palette.grey[0]}`,
          '&:hover': {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.primary.main,
          },
          '&:active': {
            backgroundColor: theme.palette.grey[0],
            color: theme.palette.emma.$494DF4,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            color: theme.palette.emma.$494DF4,
          },
          '&:active': {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.primary.main,
            textDecoration: 'underline',
          },
        },
        outlinedPrimary: {
          border: `0.5px solid ${theme.palette.primary.main}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedEmma: {
          border: `0.5px solid ${theme.palette.emma.$494DF4}`,
          color: theme.palette.emma.$494DF4,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.grey[100],
          },
          '&:active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.grey[100],
          },
          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.grey[400]}`,
            color: theme.palette.grey[400],
          },
        },
      },
    },
  };
}
