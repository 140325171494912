// @mui
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  List,
  ListItemIcon,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const RootLayoutStyle = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'enableScroll',
})(({ enableScroll }) => ({
  position: 'relative',
  maxHeight: '100vh',
  height: '100%',
  width: '100%',
  flexFlow: 'column nowrap',
  ...(enableScroll && {
    overflowY: 'auto',
  }),
}));

export const ContainerLayoutStyle = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ theme, fullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1170px !important',
  padding: '0 !important',
  flex: 1,
  '& .MuiContainer-root': {
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  [theme.breakpoints.up('md')]: {
    overflowY: 'auto',
    ...(fullWidth && {
      maxWidth: '100vw !important',
    }),
  },
}));

export const StackWrapper = styled(Stack)({
  flexDirection: 'row',
  height: '100%',
});

export const ContainerLayoutHeader = styled(Container)({
  maxHeight: '80px',
  maxWidth: '1146px !important',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 !important',
  zIndex: 99,
  '& .MuiContainer-root': {
    padding: '0 !important',
  },
});

export const DividerLayoutStyle = styled(Divider)(({ theme, ownerState }) => ({
  position: 'relative',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  ...(ownerState?.status === 'header' && {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  }),
}));

export const RootStyle = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  zIndex: theme.zIndex.appBar + 1,
  boxShadow: 'none',
  padding: '0 3.5rem !important',
  display: 'flex',
  flexFlow: 'column wrap',
  alignItems: 'center',
  height: '35px',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
}));

export const MenuItemContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  paddingX: '2rem',
  paddingY: 0,
  width: '100%',
  height: '35px',
  order: 1,
  alignSelf: 'stretch',
});

export const MenuItemBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginRight: theme.spacing(3),
  alignItems: 'center',
  padding: 0,
  width: '100%',
  height: '2rem',
  gap: '1rem',
  [theme.breakpoints.up('md')]: {
    marginRight: '3rem',
  },
}));

export const BoxMenu = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  paddingX: '1rem',
  paddingY: '0.5rem',
  height: '2rem',
  margin: 'auto 0',
  [theme.breakpoints.up('md')]: {
    flexBasis: '8rem',
    justifyContent: 'flex-end',
  },
}));

export const LinkStyle = styled(Link)({
  textTransform: 'uppercase',
  ':hover': {
    backgroundColor: 'transparent',
  },
});

export const LanguageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  flexBasis: '2.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  paddingX: '1rem',
  paddingY: '0.5rem',
  height: '2rem',
  order: 4,
  gap: '0.5rem',
  [theme.breakpoints.up('sm')]: {
    flexBasis: '6rem',
    justifyContent: 'flex-end',
  },
  ':hover': {
    cursor: 'pointer',
  },
}));

export const IconButtonStyle = styled(IconButton)(({ open }) => ({
  width: '1rem',
  height: '1rem',
  ...(open && {
    backgroundColor: 'action.selected',
  }),
  '&:hover': {
    background: 'none',
  },
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: '100%',
  height: '100%',
});

export const SubHeaderToolbar = styled(Toolbar)(({ theme }) => ({
  minWidth: '100%',
  height: '80px',
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },
}));

export const FooterContainer = styled(Container)(({ theme }) => ({
  maxWidth: '1146px !important',
  minHeight: '5.75rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.contrastText,
  padding: '0 !important',
}));

export const FooterWrapper = styled('footer')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const FooterItemStyle = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '27px',
});

export const FooterMobileContainer = styled(Box)({
  position: 'relative',
  textAlign: 'center',
  zIndex: 999,
});

export const FooterMobileWrapper = styled('footer')({
  padding: '1rem 27.5px',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  alignItems: 'flex-end',
});

export const DividerStyle = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  width: '100vw',
}));

export const DrawerHeader = styled(Box, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 24px',
    color: palette.grey[0],
    cursor: 'pointer',
    ...(active && {
      backgroundColor: ' rgba(255, 255, 255, 0.25)',
      fontFamily: theme.typography.fontFamilySecondary,
      fontWeight: 500,
    }),
  })
);

export const MenuItemSidebar = styled(Stack, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5rem 2.75rem',
    color: theme.palette.grey[0],
    width: '100%',
    borderRadius: '8px',
    ':hover': {
      backgroundColor: theme.palette.grey[500],
      mixBlendMode: 'luminosity',
      cursor: 'pointer',
    },
    ...(active && {
      backgroundColor: ' rgba(255, 255, 255, 0.25)',
      '& .MuiTypography-root': {
        fontFamily: theme.typography.fontFamilySecondary,
        fontWeight: 500,
      },
    }),
  })
);

export const DrawerFooter = styled(Stack)(() => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  alignItems: 'center',
  padding: '20px 24px',
}));

export const SidebarContent = styled(Box)(({ theme }) => ({
  minWidth: '300px',
  height: '100%',
  padding: '16px 0',
  background: `linear-gradient(135.23deg, ${palette.emma.$494DF4} 0%, ${palette.emma.$9FBEAF} 100%)`,
  position: 'sticky',
  top: '0',
  zIndex: 999,
  [theme.breakpoints.down('md')]: {
    minHeight: '520px',
  },
}));

export const HeaderBannerMain = styled(Stack)(({ theme }) => ({
  height: '110px',
  background: `linear-gradient(to right, ${theme.palette.emma.$494DF4}, ${theme.palette.emma.$9FBEAF})`,
  color: theme.palette.grey[0],
  padding: '0.5rem',
}));

export const HeaderContent = styled(Stack)(() => ({
  height: '44px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 0',
}));

export const ScrollSidebar = styled(Stack)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('md')]: {
    maxHeight: '100vh',
    height: '100%',
    overflowY: 'scroll',
  },
}));

export const MenuSidebarItemStyle = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active, ownerState }) => ({
  alignItems: 'center',
  width: '100%',
  padding: '0.5rem 2rem',
  marginBottom: '.5rem',
  ':hover': {
    backgroundColor: '#F0F0F066',
    cursor: 'pointer',
  },
  ...(active && {
    backgroundColor: ' rgba(255, 255, 255, 0.25)',
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  }),
  ...ownerState,
}));

export const FlexSpaceStyle = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const TranslateStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.grey[300],
}));

export const TranslateTypo = styled(Typography, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    ':hover': {
      cursor: 'pointer',
      color: theme.palette.action.hover,
    },
    ...(active && {
      color: `${theme.palette.grey[0]} !important`,
    }),
  })
);

export const ButtonLogout = styled(Button)(({ theme }) => ({
  width: '223px',
  height: '44px',
  fontSize: '20px',
  [theme.breakpoints.up('md')]: {
    width: '209px',
  },
}));

export const ListFooterItemContainer = styled(List)({
  display: 'flex',
  width: '100%',
});
