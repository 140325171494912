import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Card from './Card';
import ControlLabel from './ControlLabel';
import CssBaseLine from './CssBaseLine';
import Input from './Input';
import Link from './Link';
import List from './List';
import LoadingButton from './LoadingButton';
import Menu from './Menu';
import Paper from './Paper';
import Select from './Select';
import Skeleton from './Skeleton';
import Slider from './Slider';
import SvgIcon from './SvgIcon';
import Tabs from './Tabs';
import Table from './Table';
import TextField from './TextField';
import Typography from './Typography';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Button(theme),
    ButtonGroup(theme),
    Card(theme),
    ControlLabel(theme),
    CssBaseLine(theme),
    Input(theme),
    Link(theme),
    List(theme),
    LoadingButton(theme),
    Menu(theme),
    Paper(theme),
    Select(theme),
    Skeleton(theme),
    Slider(theme),
    SvgIcon(theme),
    Tabs(theme),
    Table(theme),
    TextField(theme),
    Typography(theme)
  );
}
