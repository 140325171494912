import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// routes
import { PATH_API } from '../../routes/paths';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  policies: null,
  policy: null,
  coverage: null,
};

const slice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    // * START LOADING,
    startLoading(state) {
      state.isLoading = true;
    },

    // * HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // * GET POLICIES
    getPoliciesSuccess(state, action) {
      state.isLoading = false;
      state.policies = action.payload;
    },

    // * GET POLICY
    getPolicySuccess(state, action) {
      state.isLoading = false;
      state.policy = action.payload;
    },

    // * GET POLICY BENEFIT
    getPolicyBenefitSuccess(state, action) {
      state.isLoading = false;
      state.benefit = action.payload;
    },

    // * GET POLICY COVERAGE
    getPolicyCoverageSuccess(state, action) {
      state.isLoading = false;
      state.coverage = action.payload;
    },

    // * CLEAR POLICY STATE
    clearPolicyState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default slice.reducer;

export const { clearPolicyState } = slice.actions;

export function getPolicies(nationalID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const {
        data: { data },
      } = await axios.post(PATH_API.policy.policiesList, { nationalID });

      dispatch(slice.actions.getPoliciesSuccess(data?.policyList || null));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPolicyDetail(policyNo, memberNo, dpntNo, policyStatus) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.post(PATH_API.policy.policyDetail, {
        policyNo,
        memberNo,
        dpntNo,
        policyStatus,
      });

      dispatch(slice.actions.getPolicySuccess(data?.data || null));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPolicyCoverage(policyNo, memberNo, dpntNo, policyStatus) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const { data } = await axios.post(PATH_API.policy.policyCoverage, {
        policyNo,
        memberNo,
        dpntNo,
        policyStatus,
      });

      dispatch(slice.actions.getPolicyCoverageSuccess(data?.data || null));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
