import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
// redux
import { useSelector } from '../redux/store';
// hooks
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
import useLocales from '../hooks/useLocales';
// routes
import { PATH_LOCAL } from '../routes/paths';
// components
import Image from '../components/Image/Image';
import MobileHeaderMenu from '../components/MobileHeaderMenu/MobileHeaderMenu';
//
import { FlexSpaceStyle, HeaderBannerMain } from './Styled';
import { handleNameHeader, mappingHeaderTitle } from './events';

MobileMainHeader.propTypes = {
  onOpen: PropTypes.func.isRequired,
  memberName: PropTypes.string,
};

export default function MobileMainHeader({ onOpen, memberName }) {
  const theme = useTheme();

  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState('');

  const { policy } = useSelector((state) => state.policy);

  const { currentLang, translate } = useLocales();

  const { pathname } = useLocation();
  const customHeader = useSelector((state) => state.customHeader);

  const indexPage = useMemo(() => ['PATH_AFTER_LOGIN', 'selectPolicyYear', 'allPolicyPast'], []);

  const dynamicParams = useParams();

  const gaEventTracker = useAnalyticsEventTracker('Button click');

  useEffect(() => {
    if (pathname.includes(PATH_LOCAL.claimSubmission) && customHeader !== '') {
      setPageTitle(customHeader);
    } else {
      setPageTitle(mappingHeaderTitle(PATH_LOCAL, pathname, dynamicParams, policy));
    }
  }, [currentLang, dynamicParams, pathname, policy, customHeader]);

  useEffect(() => {
    if (indexPage.includes(pageTitle)) {
      setPageTitle('');
    }
  }, [pageTitle, indexPage]);

  return (
    <>
      {/* Header 143 */}
      {pageTitle ? (
        <MobileHeaderMenu
          name={handleNameHeader(dynamicParams, pageTitle, translate)}
          onOpen={onOpen}
        />
      ) : (
        <HeaderBannerMain>
          <FlexSpaceStyle>
            <Box
              onClick={() => {
                gaEventTracker('Navigate to personal information page', 'User icon button');
                navigate(PATH_LOCAL.profile);
              }}
            >
              <Image src="/assets/icon_user.svg" />
            </Box>
            <Image src="/assets/icon_emma_by_axa.png" sx={{ width: '143px' }} />
            <IconButton onClick={onOpen}>
              <MenuIcon style={{ color: theme.palette.grey[0] }} />
            </IconButton>
          </FlexSpaceStyle>

          {/* Name section */}
          <Box>
            <Typography variant="h5Reg" marginLeft={'7px'}>
              {translate('sidebar.hello')}
            </Typography>
            <Typography variant="h5" marginLeft={'7px'}>
              {memberName}
            </Typography>
          </Box>
        </HeaderBannerMain>
      )}
    </>
  );
}
