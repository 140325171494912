import { isEmpty } from "lodash";

export function preventChangePage() {
    const elementsWithSameId = document.querySelectorAll('#prevent-changepage');
    const valuesArray = [];

    elementsWithSameId.forEach((element) => {
      valuesArray.push(element.value);
    });
    const allValuesDefined = valuesArray.some((value) => !isEmpty(value));

    return allValuesDefined;
  }

export function separateYear(dateString, currLang) {
  const [day, month, year] = dateString.split('/');
  return `${day}/${month}/${currLang === 'th' ? Number(year) + 543 : year}`;
}