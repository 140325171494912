import { styled } from '@mui/material/styles';

export const ImageContainer = styled('span')({
  lineHeight: 1,
  display: 'block',
  overflow: 'hidden',
});

export const MuiImage = styled('img')({
  objectFit: 'cover',
});
