import { useTheme } from '@mui/material/styles';
// @mui
import { Box, Stack, Typography } from '@mui/material';
// hook
import useLocales from '../hooks/useLocales';
// utils
import { SHORT_LANG } from '../config';
//
import { LanguageContainer } from './Styled';

// ----------------------------------------------------------------------

function LanguageButton({ ...sx }) {
  const { currentLang, changeLanguage } = useLocales();

  const theme = useTheme();

  return (
    <LanguageContainer sx={sx}>
      <Stack width="100%" height="100%" flexDirection="row" alignItems="center" gap={2}>
        {Object.entries(SHORT_LANG).map(([langCode, langLabel]) => (
          <Box key={langCode} padding={theme.spacing(1, 2)}>
            <Typography
              variant="h5Bold"
              color="emma.$F07662"
              sx={{ opacity: currentLang === langCode ? 1 : 0.7 }}
              onClick={() => changeLanguage(langCode)}
            >
              {langLabel}
            </Typography>
          </Box>
        ))}
      </Stack>
    </LanguageContainer>
  );
}

export default LanguageButton;
