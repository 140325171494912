import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

// Local Font
const FONT_PRIMARY = 'DBHelvethaicaX_55, SourceSansPro, sans-serif';
const FONT_SECONDARY = 'DBHelvethaicaX_65, SourceSansPro, sans-serif';
const FONT_TERTIARY = 'DBHelvethaicaX_75, SourceSansPro, sans-serif';
const FONT_PSL_TEXT_PRO_REG = 'PSL_Text_Pro_Regular, PSL_Text_Pro_Regular_Italic, sans-serif';
const FONT_PSL_TEXT_PRO_BOLD = 'PSL_Text_Pro_Bold, PSL_Text_Pro_Bold_Italic, sans-serif';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontFamilySecondary: FONT_SECONDARY,
  fontFamilyTertiary: FONT_TERTIARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    fontFamily: FONT_TERTIARY,
    fontSize: 62,
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 36, md: 62 }),
  },
  h1Bold: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    fontSize: pxToRem(31),
    lineHeight: 39 / 31,
  },
  h1Header: {
    fontFamily: FONT_TERTIARY,
    fontSize: pxToRem(21),
    lineHeight: 39 / 31,
    ...responsiveFontSizes({ xs: 21, md: 31 }),
  },
  h1BoldReg: {
    fontFamily: FONT_TERTIARY,
    fontSize: pxToRem(27),
    lineHeight: 39 / 31,
    ...responsiveFontSizes({ xs: 27, md: 31 }),
  },
  h2: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(27),
    ...responsiveFontSizes({ sm: 22, md: 25, lg: 27 }),
  },
  h2Med: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    fontSize: pxToRem(27),
    lineHeight: 1,
  },
  h2Bold: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    fontSize: pxToRem(27),
    lineHeight: 1,
  },
  h2Reg: {
    fontSize: pxToRem(27),
    lineHeight: 1,
  },
  h3: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(52),
    ...responsiveFontSizes({ sm: 26, md: 48, lg: 52 }),
  },
  h3Bold: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    lineHeight: 1.24,
    fontSize: pxToRem(25),
  },
  h4: {
    lineHeight: 1.5,
    fontWeight: 700,
    fontSize: pxToRem(42),
    ...responsiveFontSizes({ sm: 28, md: 36, lg: 42 }),
  },
  h4Med: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    fontSize: pxToRem(23),
    lineHeight: 27 / 23,
  },
  h4Bold: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    fontSize: pxToRem(23),
    lineHeight: 25 / 23,
  },
  h5: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    lineHeight: '1.5rem',
    fontSize: pxToRem(21),
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 21 }),
  },
  h5Reg: {
    fontSize: pxToRem(21),
    lineHeight: 23 / 21,
  },
  h5Med: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    fontSize: pxToRem(21),
    lineHeight: 23 / 21,
    ...responsiveFontSizes({ xs: 21, md: 2, lg: 27 }),
  },
  h5Bold: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    fontSize: pxToRem(21),
    lineHeight: 1,
  },
  h5BoldReg: {
    fontFamily: FONT_TERTIARY,
    fontSize: pxToRem(21),
    lineHeight: 1,
    ...responsiveFontSizes({ xs: 21, md: 27 }),
  },
  h5Text: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(21),
    lineHeight: 1,
    ...responsiveFontSizes({ xs: 21, md: 27 }),
  },
  h5TextSECONDARY: {
    fontFamily: FONT_SECONDARY,
    fontSize: pxToRem(21),
    lineHeight: 1,
    ...responsiveFontSizes({ xs: 21, md: 27 }),
  },
  h6: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 18, md: 22, lg: 24 }),
  },
  h6Reg: {
    fontSize: pxToRem(21),
    lineHeight: 1,
  },
  h6Med: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    fontSize: pxToRem(17),
    lineHeight: 1,
  },
  h6Bold: {
    fontFamily: FONT_TERTIARY,
    fontWeight: 700,
    fontSize: pxToRem(19),
    lineHeight: pxToRem(19),
  },
  subtitle1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(32),
  },
  subtitle2: {
    fontFamily: FONT_SECONDARY,
    lineHeight: 22 / 14,
    fontSize: pxToRem(24),
  },
  body1: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body1Reg: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ md: 21 }),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 14, md: 12.06 }),
  },
  body2Reg: {
    fontSize: pxToRem(21),
    lineHeight: pxToRem(23),
  },
  body3: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(19),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    lineHeight: 24 / 14,
    fontSize: pxToRem(22),
    textTransform: 'capitalize',
  },
  title: {
    lineHeight: 28 / 18,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 28 }),
  },
  titleReg: {
    lineHeight: 28 / 18,
    fontSize: pxToRem(20),
  },
  subtitle: {
    lineHeight: 28 / 18,
    fontSize: pxToRem(19),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 28 }),
  },
  textButton: {
    fontFamily: FONT_TERTIARY,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
  },
  textButtonReg: {
    lineHeight: 1,
    fontSize: pxToRem(17),
  },
  textReg: {
    fontSize: pxToRem(19),
    lineHeight: 1,
  },
  emmaReg: {
    fontSize: pxToRem(16),
    lineHeight: 20 / 16,
  },
  emmaReg21_19: {
    fontSize: pxToRem(21),
    lineHeight: 19 / 21,
  },
  emmaReg19_24: {
    fontSize: pxToRem(19),
    lineHeight: 24 / 19,
  },
  emmaBold12_13: {
    fontFamily: FONT_TERTIARY,
    fontSize: pxToRem(12.0615),
    lineHeight: 14 / 12.0615,
    textTransform: 'uppercase',
  },
  // PSL Text Pro
  textProBold17_17: {
    fontFamily: FONT_PSL_TEXT_PRO_BOLD,
    fontWeight: 700,
    lineHeight: 0.97,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ xs: 14, ms: 17, sm: 32, md: 17, lg: 17 }),
    // * To handle the orientation landscape of the !desktop
    '@media screen and (orientation: landscape) and (min-width: 600px) and (max-height: 600px)': {
      fontSize: pxToRem(17),
    },
  },
  textProBold15_14: {
    fontFamily: FONT_PSL_TEXT_PRO_BOLD,
    fontSize: pxToRem(15),
    fontWeight: 700,
    lineHeight: 14 / 15,
    ...responsiveFontSizes({
      xs: 14,
      ms: 15,
      sm: 32,
      md: 15,
      lg: 15,
    }),
    // * To handle the orientation landscape of the !desktop
    '@media screen and (orientation: landscape) and (min-width: 600px) and (max-height: 600px)': {
      fontSize: pxToRem(15),
    },
  },
  textProReg15_14: {
    fontFamily: FONT_PSL_TEXT_PRO_REG,
    fontSize: pxToRem(15),
    lineHeight: 0.97,
    ...responsiveFontSizes({ xs: 12, ms: 15, sm: 28, md: 15, lg: 15 }),
    // * To handle the orientation landscape of the !desktop
    '@media screen and (orientation: landscape) and (min-width: 600px) and (max-height: 600px)': {
      fontSize: pxToRem(15),
    },
  },
};

export default typography;
