import PropTypes from 'prop-types';
// @mui
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// hooks
import useLocales from '../hooks/useLocales';
import useResponsive from '../hooks/useResponsive';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
// styled
import {
  MenuItemBoxStyle,
  BoxMenu,
  DividerLayoutStyle,
  LinkStyle,
  ContainerLayoutHeader,
} from './Styled';
// routes
import { PATH_EXTERNAL } from '../routes/paths';
import LanguageButton from './LanguageButton';
import LanguagePopover from './LanguagePopover';
//

// ----------------------------------------------------------------------

MainHeader.propTypes = {
  telStyle: PropTypes.object,
  divider2Style: PropTypes.object,
  variantTel: PropTypes.string,
};

export default function MainHeader({ telStyle, divider2Style, variantTel, ...sx }) {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const { translate } = useLocales();

  const gaEventTracker = useAnalyticsEventTracker('Button click');

  return (
    <Stack
      justifyContent="center"
      maxHeight="35px"
      height="100%"
      bgcolor={theme.palette.grey[100]}
      sx={sx}
    >
      <ContainerLayoutHeader>
        <MenuItemBoxStyle>
          {/* Tel. 1159 Button */}
          <BoxMenu sx={telStyle}>
            <LinkStyle href={PATH_EXTERNAL.tel}>
              <Typography
                onClick={() => gaEventTracker('call 1159', '1159 button')}
                variant={variantTel}
                color="emma.$F07662"
                textAlign={{ lg: 'center', md: 'right' }}
              >
                {translate('tel')} 1159
              </Typography>
            </LinkStyle>
          </BoxMenu>

          {/* Language Button */}
          <DividerLayoutStyle variant="middle" orientation="vertical" flexItem sx={divider2Style} />
          {!isDesktop ? (
            <LanguagePopover
              arrowStyle={{ color: 'grey.500', width: '1rem', height: '1rem' }}
              iconStyle={{ color: 'grey.500', width: '1rem', height: '1rem' }}
            />
          ) : (
            <LanguageButton />
          )}
        </MenuItemBoxStyle>
      </ContainerLayoutHeader>
    </Stack>
  );
}
