import PropTypes from 'prop-types';
// styled
import { ImageContainer, MuiImage } from './Styled';

// ----------------------------------------------------------------------

Image.propTypes = {
  sx: PropTypes.object,
  ownerState: PropTypes.object,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default function Image({ sx, ownerState, src, alt = '', ...other }) {
  return (
    <ImageContainer sx={{ ...sx }} {...ownerState}>
      <MuiImage src={src} alt={alt} sx={{ ...other }} />
    </ImageContainer>
  );
}
