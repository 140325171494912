// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    // * specific case
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    laptop: 1024,
    laptopL: 1440,
  },
};

export default breakpoints;
