export const claimHistoryStatusLabel = [
  'submitDate',
  'admitDate',
  'dischargeDate',
  'approveDate',
  'charge',
  'nonCover',
  'overPaid',
  'coPayTotal',
  'paid',
];

export const noDisplayZeroValueList = ['nonCover', 'overPaid', 'coPayTotal']; // * Not display if values if 0
export const noDisplayPendingStatusLabel = [...noDisplayZeroValueList, 'paid', 'charge']; // * claim status code includes pending list -> claimPendingStatus

export const hasActiveInactive = 2;

export const claimPendingStatus = [
  'LB1',
  'LB4',
  'LB11',
  'PC1',
  'PC2',
  'PC3',
  'PC4',
  'W1',
  'W2',
  'W3',
  'W4',
  'W6',
  'W7',
  'W8',
  'W9',
  'W11',
  'W14',
  'W15',
  'W16',
  'D7',
  'IN1',
  'IN2',
  'IN3',
  'IN4',
  'IN5',
  'IN6',
  'IN7',
  'IN8',
  'IN10',
  'IN11',
];
