// customHeaderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const customHeaderSlice = createSlice({
  name: 'customHeader',
  initialState: '',
  reducers: {
    setCustomHeader: (_, action) => action.payload,
  },
});

export const { setCustomHeader } = customHeaderSlice.actions;
export default customHeaderSlice.reducer;
