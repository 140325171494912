// @mui
import { Typography } from '@mui/material';
// routes
import { PATH_EXTERNAL } from '../routes/paths';
// hooks
import useResponsive from '../hooks/useResponsive';
import useLocales from '../hooks/useLocales';
//
import FooterItem from './FooterItem';
import {
  FooterContainer,
  FooterItemStyle,
  FooterWrapper,
  FooterMobileContainer,
  DividerStyle,
  FooterMobileWrapper,
  ListFooterItemContainer,
} from './Styled';
import { getToken, handleFooterItem } from './events';

export default function Footer() {
  const isMobile = useResponsive('down', 'md');

  const { translate } = useLocales();

  return (
    <>
      {isMobile ? (
        <MobileFooter />
      ) : (
        <>
          <FooterContainer>
            <FooterWrapper>
              <FooterItemStyle>
                <Typography
                  variant="h2Bold"
                  color="primary.main"
                  width="100%"
                  noWrap
                  overflow="initial"
                  mr="0.7rem"
                  alignSelf="flex-end"
                  onClick={getToken}
                >
                  {translate('followUs')}
                </Typography>
                <ListFooterItemContainer sx={{ height: '100%', padding: 0 }}>
                  {handleFooterItem(PATH_EXTERNAL).map((item) => (
                    <FooterItem key={item.to} item={item} />
                  ))}
                </ListFooterItemContainer>
              </FooterItemStyle>
              <Typography
                variant="h5Reg"
                color="primary.main"
                textAlign={{ lg: 'right', md: 'center' }}
                width="26.25rem"
              >
                {translate('copyRight')}
              </Typography>
            </FooterWrapper>
          </FooterContainer>
        </>
      )}
    </>
  );
}

function MobileFooter() {
  const { translate } = useLocales();

  return (
    <FooterMobileContainer>
      <DividerStyle variant="fullWidth" />
      <FooterMobileWrapper>
        <Typography variant="h5Bold" color="primary.main">
          {translate('followUs')}
        </Typography>
        <Typography variant="emmaReg" color="primary.main" order="3">
          {translate('copyRight')}
        </Typography>
        <ListFooterItemContainer
          sx={{ justifyContent: 'center', order: 2, padding: 0, gap: '1rem', m: '0.5rem 0 1rem 0' }}
        >
          {handleFooterItem(PATH_EXTERNAL).map((item) => (
            <FooterItem key={item.to} item={item} padding={0} width="unset" />
          ))}
        </ListFooterItemContainer>
      </FooterMobileWrapper>
    </FooterMobileContainer>
  );
}
