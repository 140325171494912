import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 24px 16px',
  background: theme.palette.grey[0],
  boxShadow: 'inset 0px -1px 0px rgba(204, 204, 204, 0.25)',
}));
