import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// hooks
import useLocales from '../../hooks/useLocales';
//
import { ButtonStyle, RedirectControllerStyle } from './Styled';
import { handleExpand } from './events';

export default function RedirectController() {
  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { translate } = useLocales();

  useEffect(() => {
    setExpanded(handleExpand());
  }, [pathname]);

  return (
    <RedirectControllerStyle expanded={expanded}>
      <ButtonStyle variant="primaryEmma" onClick={() => navigate(-1)}>
        {translate('backButton')}
      </ButtonStyle>
    </RedirectControllerStyle>
  );
}
