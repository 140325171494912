import axios from 'axios';
// config
import { HOST_ENDPOINT } from '../config';
// routes
import { PATH_LOCAL } from '../routes/paths';
// constants
import {
  AUTH,
  CLAIMSUBMIT,
  POLICY,
  STATUS_500,
  STATUS_503,
  errorPageList,
} from '../constants/general';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_ENDPOINT,
});

axiosInstance.interceptors.request.use((config) => {
  if (config.headers.Authorization) {
    return config;
  }

  const idToken = sessionStorage.getItem('idToken');
  config.headers.Authorization = `Bearer ${idToken}`;

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { pathname } = window.location;

    console.log('axios error => ', error);

    if (!errorPageList.includes(pathname)) {
      console.log('error.response => ', error?.response);

      if (error?.response?.status === STATUS_503) {
        window.location.replace(PATH_LOCAL.page503);
      }
      const preventRedirect500 = [AUTH, CLAIMSUBMIT, POLICY];
      const isPathPrevent = preventRedirect500.some((item) => pathname.includes(item));

      if (error?.response?.status === STATUS_500 && !isPathPrevent) {
        window.location.replace(PATH_LOCAL.page500);
      }
    }

    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;
