import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
// routes
import { PATH_API, PATH_AUTH } from '../routes/paths';
// redux
import { useDispatch } from '../redux/store';
import { clearClaimState } from '../redux/slices/claim';
import { clearPolicyState } from '../redux/slices/policy';
// utils
import axios from '../utils/axios';
import { getValueInSessionStorage, setValueInSessionStorage } from '../utils/cacheSession';
import { setSession } from '../utils/token';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handler = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handler[action.type] ? handler[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchReduxStore = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const userInSessionStorage = getValueInSessionStorage('userInfo');

        if (userInSessionStorage?.clientID) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userInSessionStorage,
            },
          });

          return;
        }

        const { user: userInfo } = state;

        if (state.user) {
          const response = await axios.post(PATH_API.user.getProfile, {
            nationalID: userInfo?.nationalID,
          });

          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (error) {
        console.error(error);

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (mobilePhone, otpNumber, nationalID) => {
    const accessToken = sessionStorage.getItem('accessToken');
    const { status, data } = await axios.post(PATH_API.auth.verifyOTP, {
      nationalID,
      otpNumber,
      mobilePhone,
      accessToken, // * to access MW & for mock data
    });

    if (status === 200) {
      const { user, idToken } = data;

      const userInfo = {
        ...user,
        nationalID,
      };
      setValueInSessionStorage('userInfo', userInfo);
      setSession(idToken);

      dispatch({
        type: 'LOGIN',
        payload: { user: userInfo },
      });

      const expirationTime = 14400000; // 4 hour
      setTimeout(() => {
        sessionStorage.clear();
        window.location.href = PATH_AUTH.login;
      }, expirationTime);
    }
  };

  const logout = useCallback(() => {
    dispatch({ type: 'LOGOUT' });
    dispatchReduxStore(clearClaimState());
    dispatchReduxStore(clearPolicyState());
    setSession(null);
  }, [dispatchReduxStore]);

  const value = useMemo(
    () => ({
      ...state,
      login,
      logout,
    }),
    [logout, state]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
