// ----------------------------------------------------------------------

export default function Link() {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        borderRadius: 0,
      },
    },
  };
}
