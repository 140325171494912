export const enLang = {
  // * LOGIN
  welcomeText: 'Welcome to Employee Portal\nKrungthai-AXA Life Insurance',
  loginText: 'Login',
  privacyPolicy: 'Privacy Policy',
  and: 'and',
  tAndC: 'Terms and Conditions',
  otpDesc: 'We will send OTP via SMS.\nPlease fill your ID Number in this form below.',
  idNumberLabel: 'Identification number / Passport number',
  errorNationalIdRequired: 'Identification number is required',
  'Invalid ID number':
    'The information was not found / the information does not\nmatch what the company recorded.',
  mobileInvalid: 'Invalid phone number, you need to enter a valid mobile number',
  selectPhoneTitle: 'Please select a phone number to\nreceive an OTP',
  selectPhoneDesc:
    'If your phone number is not found, or it has recently changed, or cannot receive OTP, please contact Customer Service Center',
  phoneLabel: 'Phone Number',
  sendOtpButton: 'Send OTP',
  verifyTitle: 'Verification',
  verifyDesc: 'Enter your OTP we sent via SMS\nto +66XXXXX (From KTAXA)',
  otpLabel: 'OTP',
  receiveSms: "Don't receive SMS? ",
  sendAgainIn: 'Send again in',
  min: 'mins',
  sendAgainButton: 'Send again',
  nextButton: 'Next',
  confirmButton: 'Confirm',
  cancelButton: 'Cancel',
  changeMobilePhoneDesc:
    'If you have changed your phone number or cannot receive\nOTP, please contact customer service center',
  errorOtpInvalidDigit: 'OTP number must be exactly 6 digits long and only contain numbers',
  errorOtpRequired: 'Otp number is required',
  otpExpiredTitle: 'OTP has expired',
  otpExpiredDesc:
    'The OTP code provided has expired.\nYou can request a code by clicking “Send OTP”.',
  cannotSendOtpTitle: 'The system cannot send OTP',
  cannotSendOtpDesc:
    'Sorry, OTP cannot be sent at this time. \n Please wait a moment Then try sending the OTP again.',
  okButton: 'OK',
  dataNotFoundTitle: 'Data not found',
  dataNotFoundDesc:
    'The information was not found / the information does not\nmatch what the company recorded.',

  textAssistanceText: 'for assistance.',
  otpInvalidText: 'OTP code is invalid. Please try again.',
  mobileRequired: 'Phone number is required',
  userNotFound: 'User not found',
  'not found data':
    'The information was not found / the information does not\nmatch what the company recorded.',
  suggestInvalidNationalId:
    'If you have a problem with your ID number cannot request\nfor OTP, please contact customer service center',
  suggestInvalidMobilePhone:
    'If you have changed your phone number or cannot receive\nOTP, please contact customer service center',
  "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.":
    'The information was not found / the information does not match what the company recorded.',
  'Wrong phone number or verification code.': 'OTP code is invalid. Please try again.',
  "You've reached the maximum number of attempts. Please try to login again.":
    'The OTP code provided has expired.\nYou can request a code by clicking “Send OTP”.',
  placeholderNIDPassport: 'Identification number or Passport number',
  'The specified new phone_number already exists':
    'The information was not found / the information does not\nmatch what the company recorded.',
  'get employee profile success':
    'The information was not found / the information does not\n match what the company recorded.',
  '403 Forbidden':
    'The information was not found / the information does not\n match what the company recorded.',
  'You cannot consume this service':
    'The information was not found / the information does not\n match what the company recorded.',

  //  * CLAIM
  claim: {
    claimInquiryText: 'Claim History',
    policyCoverageText: 'Policy & Coverage',
    historyText: 'History',
    'Group Health Package': 'Group Health Package',
    'Group PA Package': 'Group PA Package',
    'Group Credit Life': 'Group Credit Life',
    policyPast1Year: 'All policy past 1 year',
    currentlyViewing: 'You are currently viewing',
    allPolicyPast: 'All policy past 1 year',
    selectPolicyYear: 'Select Policy Year',
    activeText: 'Active',
    inactiveText: 'Inactive',
    backToTopButton: 'Back \n to top',
  },
  // * CLAIM HISTORY
  claimHistory: {
    claimDetails: 'Claim Details',
    claimHistoryText: 'Claim History List',
    claimNo: 'Claim No.',
    claimStatus: 'Claim status',
    claimStatusCode: 'Claim Status Code',
    historyFlag: 'History Flag',
    pendingFlag: 'Pending Flag',
    apiPolicyYear: 'API Policy Year',
    apiText: 'API Text',
    paidHospitalNetworkText: 'Paid to hospital network',
    claimInfo: 'Claim Information',
    notifyDate: 'Notify date',
    approveDate: 'Approve date',
    overPaid: 'Over Paid',
    admitDate: 'Admit date',
    nonCover: 'Non cover amount',
    coPayTotal: 'Total co-payment',
    paid: 'Total Claim paid',
    discoverDetails: 'Discover Details',
    serviceType: 'Service Type',
    serviceTypeCode: 'Service Type Code',
    serviceTypeGroup: 'Service Type Group',
    serviceTypeDescription: 'Service Type Description',
    inpatient: 'Inpatient',
    hospital: 'Hospital',
    submitDate: 'Submit date',
    dischargeDate: 'Discharge date',
    accidentDate: 'Accident date',
    charge: 'Total Claim Amount',
    discount: 'Discount',
    exgratia: 'Exgratia',
    overLimit: 'Overall limit',
    suborgration: 'Suborgration',
    assessmentStatus: 'Assessment Status',
    customerCharge: 'Customer Charge',
    customerPaid: 'Customer Paid',
    customerException: 'Customer Exception',
    icd10_1_code: '',
    icd10_1_desc: '',
    icd10_2_code: '',
    icd10_2_desc: '',
    icd10_3_code: '',
    icd10_3_desc: '',
    paymentDate: 'Payment date',
    clientMailAddress: 'Client Mail Address',
    address: 'Address',
    cutOffDate: 'Cutoff date',
    remarkPolicyYear: 'Remark Policy Year',
    remarkExpense: 'Remark Expense',
    sumExMed: 'Sum Expense Med',
    sumExHb: 'Sum Expense Hb',
    sumExPa: 'Sum Expense Pa',
    notifyNo: 'Claim number',
    rider: 'Rider',
    reSubmitDate: 'Re-Submit date',
    registerDate: 'Register Date',
  },
  // * CLAIM HISTORY STATUS
  claimHistoryStatus: {
    'Claim History Status': 'Claim History Status',
    admitDate: 'Admit date',
    nonCover: 'Non cover amount',
    paid: 'Total Claim paid',
    notifyDate: 'Notify date',
  },

  // * CLAIM HISTORY DETAIL
  claimHistoryDetail: {
    faxClaimExpenseDetail: 'Fax Claim Expense Detail',
    dailyRoomBoard: 'Daily Room & Board',
    icuAdmission: 'I.C.U. Admission',
    generalHospitalServices: 'General Hospital Services',
    ambulanceServices: 'Ambulance Services',
    nursingServices: 'Nursing Services',
    specialistConsultationFee: 'Specialist Consultation Fee',
    doctorVisit: 'Doctor Visit',
    viewDetails: 'View Details',
    day: 'day',
    charge: 'Charge',
    times: 'Times',
    cover: 'Cover',
    paid: 'Total Claim paid',
    surgeonFee: 'Surgeon fee as per non surgical scheduler',
    majorMedicalGeneral: 'Major medical pay 80% General Hospital Services',
    majorMedicalDailyRoom: 'Major medical pay 80% Daily Room & Board',
    majorMedicalSurgicalFee: 'Major medical pay 80% Surgical fee',
  },

  // * HOME
  home: {
    protectionServices: 'PROTECTION SERVICES',
    myPolicies: 'My Policies',
    subMyPolicies: '',
    myInsuranceCards: 'My Insurance',
    subMyInsuranceCards: 'Cards',
    claim: 'Claim History',
    subClaim: '',
    downloadDocuments: 'Download',
    subDownloadDocuments: 'Documents',
    myPoliciesList: 'My Policies',
    policyCoverage: 'Activated Policy & Coverage',
    hospitalNetwork: 'Hospital',
    subHospitalNetwork: 'Network',
    history: 'History Past 1 Year',
    allPolicyYear: 'All Inactivated Policy & Coverage',
    selectPolicyYear: 'Select Policy Year',
    active: 'Active',
    inactive: 'Inactive',
    moreDetails: 'More Details',
  },

  // * POLICY
  policy: {
    servicePolicy: 'Service from this policy',
    basicPlan: 'Benefits and Coverage: Basic plan',
    additionalPlan: 'Benefits and Coverage: Additional Rider (s)',
    familyPlan: 'Benefits and Coverage for Family member (s)',
    myInsuranceCards: 'My Insurance Cards',
    balanceOpd: 'Balance (OPD)',
    claimSubmission: 'Submit Claim',
    claimHistory: 'Claim',
    claimHistory2: 'History',
    downloadDocuments: 'Download Documents',
    policyBenefit: 'Policy Benefit',
    inpatientBenefits: 'Inpatient Benefits',
    outPatientBenefits: 'Out-Patient Benefits',
    groupLifeInsuranceBenefits: 'Group Life Insurance Benefits',
    dentalBenefits: 'Dental Benefits',
    maternityBenefits: 'Maternity Benefits',
    majorMedicalBenefits: 'Major Medical Benefits',
    otherBenefits: 'Other Benefits',
    postHospitalBenefits: 'Post-Hospital Surgery treatment after admission Benefits',
    preHospitalizationBenefits: 'Pre Hospitalization before admission Benefits',
    xray: 'X-Ray, Lab Test Benefits',
    active: 'ACTIVE',
    inactive: 'INACTIVE',
    outpatientCoverage: 'Outpatient coverage',
    dataUpdateAt: 'Information updated on',
    opdCoverageDesc: 'Coverage Balance Outpatient (OPD)',
    opdCoverageSubDesc:
      '* However, the reported amount may not have been calculated for the amount of medical expenses that In the process of submitting claim documents to the company.',
    footerDesc: '*Remark: The coverage conditions are as specified in the policy contract.',
    confirmOK: 'OK',
    notFoundHeader: `Not found / Data does not match in company's record`,
    notFoundDescription:
      "We're sorry, but the information was not found / the information does not match in company’s record.",
    notFoundDescription1: 'Please contact Customer Service Center',
    notFoundDescription2: 'for assistance.',
    notFoundSubDescription: 'View application usage information or ',
    notFoundLinkSubDescription: 'view additional product information at www.krungthai-axa.co.th',
  },

  // * CLAIM SUBMISSION
  claimSubmission: {
    typeHealthClaim: 'OPD / ผู้ป่วยนอก',
    cantSubmit1: 'Unfortunately, This policy does not cover',
    cantSubmit2: 'outpatient benefits.',
    cantSubmitDesc1: 'This policy does not cover outpatient benefits.',
    cantSubmitDesc2: 'Please choose another policy.',
    claimServiceTitle: 'OPD Quick Claim Service',
    claimReqText: 'Submit OPD claim with amount not exceed ',
    claimOPDText: '',
    claimMaximum: '',
    claimReqDescText:
      '(Including dental, health check-up, vaccines and optical according to the conditions specified in the policy)',
    initiateRequest: 'Initiate the Request',
    createRequest: 'Create Request',
    typesOfHealthClaim: 'Types of Health Claims',
    insuredName: 'Insured Name',
    insuredFamilyName: "Insured Name (In case of an employee's family)",
    optional: 'Optional',
    insuredFamilyNamePlaceholder: 'Choose the insured name',
    email: 'Email to receive confirmation of claim form',
    enterEmail: 'Please enter your email.',
    enterEmailConfirm: 'Please enter your email confirmation.',
    emailDoesNotMatch: 'Invalid email confirmation. Please check again.',
    emailFotmatIncorrect: 'Incorrect email format. Please enter again.',
    invalidClaimAmount: 'Please enter claim amount.',
    confirmEmail: 'Email confirmation',
    visitDate: 'Visit date/ Date of Treatment',
    pleaseEnterDate: 'Please enter date of treatment.',
    visitDatePlaceholder: 'DD/MM/YYYY',
    claimAmount: 'Claim amount',
    baht: 'baht.',
    bahtUndot: 'Baht',
    selectPolicy: 'Select a policy you want to claim',
    addPolicy: 'Add more policy',
    createSelectPolicyDesc:
      'Able to choose up to 5 policies. The claim amount to be deducted from the first policy and then deduct from the next policy.',
    selectPolicyDesc:
      'You can choose up to 5 policies. The claim amount to be deducted from the first policy and then deduct the next policy.',
    attachBankBook: 'Attach the first page of the bank book',
    addAttachments: 'Add Attachments',
    attachBankBookDesc: 'Savings account of the insured only.',
    attachTheReceipt: 'Attach the receipt',
    attachTheReceiptDesc:
      'Please specify a message “Used for medical expense reimbursement with Krungthai AXA Company only” in the original receipt with a blue ink before taking a picture.',
    attachTheExpenseDetails: 'Attach the expense details',
    attachMedicalCertificate: 'Attach medical certificate or treatment summary',
    attachMedicalCertificateDesc:
      'It is necessary to provide the treatment expense details for any health claim amount greater than 1,500 baht.',
    claimAmountUpTo: 'You can specify the claim amount up to',
    sampleExpenseDetails: 'Sample Expense Details',
    sampleExpenseDescript:
      'It is necessary to provide the treatment expense details for any health claim amount greater than 1,500 baht.',
    sampleReceipt: 'Sample Receipt',
    sampleReceiptDescription:
      'Please specify a message “Used for medical expense reimbursement with Krungthai-AXA Life Company only” in the original receipt with a blue ink before taking a picture.',
    sampleMedical: 'Sample Medical Report for OPD',
    next: 'Next',
    back: 'BACK',
    toTop: 'TO TOP',
    resultSelectPolicyTitle: 'The policy you want to claim',
    selectPolicyTitle: 'Select a policy you want to claim',
    selectPolicyPlaceholder: 'Select policy from the list below',
    noOptionPolicy: 'No other policy was found to cover outpatient benefits.',
    policyCanBeAdded: 'Policy that can be added',
    confirmOK: 'OK',
    comfirmRequestClaim: 'Confirm',
    policyWantToMakeClaim: 'The policy you want to make a claim',
    attachedClaimsSupportingDocuments: 'Attached Claims Supporting Documents',
    cancel: 'Cancel',
    submitRequest: 'Submit Request',

    //
    consentLetter: 'Consent Letter',
    consentParagraph1:
      "I hereby give and consent physicians, hospitals, other insurance companies or related persons that contains my personal information, my health information and medical history in the past or in the future to disclose my health information and medical history to Krungthai-AXA Life Insurance Public Company Limited, an insurance company.  This includes giving consent for the exchange of my health information and medical history between the Company with the company's insurance agents or a representative of the company or the policyholder and/or insurance brokerage companies for payment under the insurance policy or take any action related to the insurance policy.",
    consentParagraph2:
      "I acknowledge that the Company will process my personal data for the benefit of fulfilling the obligations under the policy and providing the Company's services and understand the details of my personal data being processed to comply with the Personal Data Protection Act  (PDPA) as set out in the Personal Data Protection Policy as detailed in the Company's website",
    consentParagraph3:
      "In addition, a copy of this consent letter is deemed valid as the original. I am fully aware and understood well the content, the conditions and practices of the Company as listed in this document in full details.  I agree that it is correct according to my intention. Therefore, I agreed to be bound by the conditions and the Company's practice in all respects.",
    remark: 'Remark',
    remark1:
      'For the case of the insured is a juvenile, parents to sign on their behalf and specify the relationship.',
    remark2:
      'For the case of the insured is a juvenile and aged over 10 years but not over 20 years, parents or legal guardian sign with the insured and specify the relationship.',
    remark3: 'For the case of fingerprint signs, must have 2 witnesses sign together.',
    confirm: 'Confirm',

    //
    successTitle: 'Your claim request has been successfully submitted.',
    successSubtitle:
      'The result of this claim submission depends on the policy conditions. You can check the initial claim status',
    successSubtitle2: 'at the Claim History menu within 3-5 business days.',
    typesOfClaim: 'Types of Claim',
    since: 'since',
    requestDate: 'Request Date',
    backHome: 'Return to Main Page',
    failTitle: 'The request cannot be saved at this time',
    failSubtitle:
      'Sorry, the system is unable to save your health claim request. \n Please try again later.',

    cancelTitle: 'Cancel health claim submission?',
    cancelDesc1: 'The system will not save the information.',
    cancelDesc2: 'To cancel the request, select "Cancel Request"',
    cancelDesc3: 'or select "Continue“ to continue,.',
    cancelContinue: 'Continue',
    cancelButton: 'Cancel the Request',
    closeButton: 'OK',
    maxAttachedFileTitle: 'The maximum file size limit has been reached.',
    maxAttachedFileDetail: 'Please upload files with a total size less than 5 MB.',
    warningMaxAttachFile:
      'The total attached file size after system resized should not exceed 5 MB. \n The current size is equal to {SIZE} MB.',
  },

  // * MONTHS
  month: {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },

  // * INSURANCE
  insurance: {
    myInsurance: 'My Insurance Cards List',
  },

  // * INSURANCE GROUP
  insuranceGroup: {
    insuranceGroup: 'Insurance Group',
    policyOwner: 'Policy Owner',
  },

  // * INSURANCE DETAIL
  insuranceDetail: {
    myInsuranceCardTitle: 'My Insurance Card',
    flipBackText: 'See Backside',
    downloadText: 'Download',
    backward: 'Back',
    eCard: 'E-card',
  },

  // * MOBILE HEADER
  mobileHeader: {
    groupHealthPackage: 'Group Health Package',
    myAccount: 'My Account',
  },

  // * POLICY COVERAGE
  _policyCoverage: {
    'Group Health Package': 'Group Health Package',
    'Group PA Package': 'Group PA Package',
    'Group Credit Life': 'Group Credit Life',
    THB: 'Baht',
    times: 'Times',
  },

  // * SIDEBAR
  sidebar: {
    hello: 'Hello',
    myPolicies: 'My Policies',
    claim: 'Claim',
    claimInquiry: 'Claim History',
    claimSubmission: 'Claim Submission',
    hospitalNetwork: 'Hospital Network',
    logout: 'Log out',
    logoutDes: 'Are you sure you want to log out?',
    ok: 'OK',
    cancel: 'cancel',
  },

  // * TERMS LIST
  termsList: {
    header: 'Terms and Conditions for Use of Services of "Group Employee Portal"',
    headerCut: 'Terms and Conditions for Use of Services of',
    headerAppName: '"Group Employee Portal"',
    subHeader:
      'In using the services of the Company’s Application, in accordance with the detailed services prescribed by Krungthai-AXA Life Insurance Public Company Limited (the "Company"), you agree to be bound by the following terms and conditions.',
    termsCondition1: 'Definition',
    termsCondition2: 'Objective of the System ',
    termsCondition3: 'Procedure of Entry to Use the System',
    termsCondition4: 'Use of the Services of the System',
    termsCondition5: 'Limitation of Liabilities ',
    termsCondition6: 'Scope of Jurisdiction of the Court ',
    termsCondition7: 'Collection, Use and Disclosure of Personal Data',
    data11:
      '1.1.	“Group Employee Portal” refers to the web application for the services provided to the group insured of the Company, hereinafter referred to as the "System."',
    data12:
      '1.2.	“Transaction Device” refers to a computer, mobile phone, tablet, and/or other devices as determined by the Company that are used to access and utilize the services of the System.',
    data13:
      '1.3.	“Group Insurance Policy” refers to the Master Group Policy in which the insured is the member.',
    data14:
      '1.4.	“Insured” refers to the insured according to the definition as determined in Group Insurance Policy.',
    data21:
      'The objective of this web application, hereinafter collectively referred to as the "System," is to provide services exclusively to the group insured of the Company and offer related products or services.',
    data31:
      'To access and use this System, you can log in by visiting the website address (URL) specified by the Company and providing your identification card or passport number, along with the mobile phone number that you have provided to the Company. If the provided data is accurate, the Company will send a one-time password (OTP) to that phone number via SMS message. This password will enable you to access and use the System.',
    data41:
      '4.1.	You agree to be bound to and comply with the terms and conditions for use of the services as prescribed by the Company.',
    data42: '4.2.	Upon your access or use of this System, you affirm that you are the insured.',
    data43:
      '4.3.	The data provided within this System does not encompass the complete terms and conditions of the related policy, either in its entirety or in part. It is strongly advised that you thoroughly read the policy document and understand the terms and conditions of the policy on your own. Furthermore, the use of this System does not relieve you from the responsibility of adhering to the terms specified in any rules or general practices regarding accident or indemnity claims.',
    data44:
      "4.4.	The access to the services and the application to receive any insurance products presented in this System may be limited for certain individuals or in specific countries. The Company shall not provide services or sell products to individuals if such services or sales are prohibited by the laws of the individual's home country or any other relevant jurisdiction. However, it depends on the prior-verification of all related parties with his/her legal advisors that his/her legal and financial status is beneficial for him/her to have the right to use services and apply for receiving the products presented herein.",
    data45:
      '4.5.	In submitting the data related to the insured’s indemnity claim, you affirm that you are the insured and accept these Terms and Conditions, as well as the declaration of personal data collection. You have the full duty and responsibility for valid execution and agree to reimburse to the Company if any damage or claim from the said execution occurs.',
    data46:
      '4.6.	The Company’s Return/Refund/Cancellation Policy (if any) shall be specified in the related insurance policy applied by you. Otherwise, the insurance premium shall not be returned/refunded if you cancel the insurance policy.',
    data47:
      '4.7.	While your address is entered to apply any insurance product, please do not enter the word "Care of" and/or temporary address, such as internet café’s address.',
    data48:
      '4.8.	All data herein may be altered without advance notice. You well understand that you shall first accept the said terms and conditions so that the System can be accessed and/or used. Therefore, you accept and agree to comply with the said terms.',
    data51:
      '5.1.	The Company’s acceptance of any data supplied by you through this System shall not be deemed or taken to interpret as the Company’s confession. In judging the indemnity claim, the data submitted to the Company through this System shall be partially considered, and you guarantee and affirm that the said data is true and correct, and all data that may be significant in any manners with the indemnity claim has been completely disclosed. The Company would like to inform that the terms presented herein are just the indications, not the entire comprehensions. The Company may need to request the insured to supply the additional data, document and evidence of indemnity claim to support the consideration of the insured’s indemnity depending on the circumstance of each case. The liability of reimbursement shall be always subject to the terms and conditions of the related policy.',
    data52:
      '5.2.	Even though the attempt is taken with all one’s ability to ensure that the data available in or accessed through this System is correct, the Company does not certify or guarantee the correctness, adequacy, acceptance, reasonability or completeness and validity either in any characteristics of the said data, or confess to the loss or damage either in whole or in part, either directly or indirectly due to error, incorrectness or omission of any acts.',
    data53:
      '5.3.	There are no warranties of any kind, whether explicit, implicit, legal, or otherwise, pertaining to the data available in or accessed through this System, or to the System itself.',
    data54:
      '5.4.	By accessing or using this System, you expressly acknowledge and accept the associated risks. The Company shall not assume any responsibility or be liable for any losses or damage arising from your access or use of this System, including the download or use of any data.',
    data55:
      '5.5.	You acknowledge that you are well informed and evidently agree that in using this System, the Company shall monitor, audit and record all of your activities implemented in this System.',
    data56:
      '5.6.	In the case of which the data in or accessed through this System is in conflict with the related policy, the effect of the terms in the said policy and any revised policy shall prevail to the extent of conflict only.',
    data57:
      '5.7.	The Company does not guarantee or warrant that this System will always be available and free from disruptions to access. The Company will make reasonable efforts to correct any faults that may arise. However, there is no assurance of any delay, failure, error, omission, damage to transmitted data, transmission of viruses or contaminants, or any other actions that may adversely affect other systems or cause damage to your transaction device as a result of accessing and using the system. It is your sole responsibility to take appropriate measures for the protection and prevention of your transaction tool, as well as for data backup.',
    data58:
      '5.8.	Your e-mail address is beyond the control of the Company, and the Company assumes no responsibility for the contents of your e-mail address. The access and use of your e-mail address are entirely at your own risk and subject to any terms and conditions associated with such access or use. The provision of hyperlinks or links to your e-mail address by the Company should not be interpreted as an affirmation, suggestion, endorsement, warranty, or advice from the Company regarding any other individuals, services, products, or contents provided by other parties within your system/platform or any form of collaboration with other parties and the aforementioned system/platform. The Company is not a party to any contract entered into between you and the service provider of the external system/platform unless explicitly specified or agreed upon by the Company.',
    data59:
      '5.9.	The Company cannot guarantee the absolute security of e-mails and other communications sent to the Company and/or this System through the internet. The Company shall not be held responsible for any damages incurred by you in the event that you send e-mails or messages to the Company, or if the Company sends e-mails or messages to you as per your request, through the internet.',
    data510:
      '5.10.	Due to the nature of the said internet, transaction or communication, it may cause interruption, data transmission interruption, data transmission delay, and improper data transmission, the Company must not be responsible for the case of interruption of the communication system that is within the Company’s control, possibly affecting the correctness or suitability of communication time and transactions submitted or obtained by you.',
    data511:
      '5.11.	In the event that any statement in these Terms and Conditions is deemed invalid, the remaining statements shall continue to be in effect. If any statement herein is found to be unlawful, void, or unenforceable, such statement shall be considered severed from these Terms and Conditions, and the remaining statements shall remain valid and enforceable.',
    data61:
      '6.1.	These Terms and Conditions are governed by and interpreted in accordance with the laws of the Kingdom of Thailand. You and the Company mutually agree to submit to the non-exclusive jurisdiction of the courts of the Kingdom of Thailand for the purpose of resolving any disputes that may arise from these Terms and Conditions.',
    data71:
      "7.1.	You acknowledge and agree that the Company may collect, use, or disclose your personal data, which is provided or available to the Company, obtained by the Company, or accessed from other sources. This data may be disclosed to agencies, organizations, or legal entities with whom the Company has contractual relationships or affiliations as well as the relevant authorities or committees in accordance with the applicable Notifications. The purpose of such data disclosure is to support the Company's services, which may include but are not limited to, assessing indemnity claims, hiring or engaging other individuals or entities for representation, both within and outside of Thailand. These entities may include insurance companies, reinsurance companies, medical examiners, indemnity verifiers, industry associations/federations, and others involved in after-sales services to customers. Additionally, data may be utilized for web browsing behavior analysis through cookies and the use of data analysis systems to enhance and improve your overall user experience.",
    data72:
      "7.2.	You are fully aware and understand that you can further familiarize yourself with the purpose of the use of your personal data and your rights as outlined in the Company's Personal Data Protection Policy. The policy can be accessed at",
    data72link: 'https://www.krungthai-axa.co.th/th/compliance/privacy-policy',
    subFooter:
      'In addition, when you create an account, we process your information as described in our',
    linkSubFooter: 'Information Security Awareness.',
    footer:
      "By clicking 'AGREE' button, I agree that Krungthai-AXA Life Insurance Public Company Limited and /or its related companies may retain, use or disclose any of the information collected or held to third parties (within or outside Thailand, such as insurance, reinsurers, medical examiners, claims investigators and industry association / federation etc.) for purpose of providing after sale service to customer.",
    accept: 'Accept',
  },

  // * COVERAGE GROUP
  coverageGroup: {
    inPatientBenefitLumpSum: 'In-Patient Benefit (Lump sum)',
    inPatientBenefitPerDisability: 'In-patient benefit (per disability)',
    majorMedicalLumpSum: 'Major medical (Lump sum)',
    majorMedicalPerDisability: 'Major medical (per disability)',
    preHospitalisation: 'Pre-hospitalisation',
    postHospitalisation: 'Post-hospitalisation',
    outPatientBenefit: 'Out-Patient Benefit',
    laboratoryTest: 'Laboratory test',
    mentalHealth: 'Mental health',
    optical: 'Optical',
    medicalExpenseFromAccident: 'Medical expense from accident',
    dentalPerVisit: 'Dental (per visit)',
    dentalLumpSum: 'Dental (Lump sum)',
    maternity: 'Maternity',
    none: 'none',
  },

  // * COVERAGE MAJOR MED LUMPSUM
  coverageMajorMedLumpSum: {
    groupDescription:
      'Actual eligible expense when combined with the benefits items 1 and 2, not exceed total benefit amount per year.',
  },

  // * COVERAGE IPD LUMPSUM
  coverageIPDLumpSum: {
    rb: '1. Room & board per day',
    icu: '    ICU room & board per day (2 times of item 1)',
    doct_fee: 'Doctor visit per day',
    ss_sa: 'Surgical and operative fee (non schedule)',
    ghe1: 'General medical expense',
    consult: 'Specialist consultation fee',
    ipdGruop:
      'Actual eligible expense but when combined with the benefits paid in item 1, not exceed total benefit amount per policy year',
    ghe21: 'The maximum total benefit amount per year',
    ghe22:
      'Copayment which the insured must share in the cost of medical treatment which will be paid according to the amount of benefits',
  },

  // * CS BENEFIT
  csBenefit: {
    maxiumBenefitPerYear: 'Maxium benefit per year',
    areaCoverage: 'Area coverage',
    ipd: 'IPD',
    opd: 'OPD',
    otherBenefits: 'Other Benefits',
  },

  // * COVERAGE
  coverage: {
    ab: 'Ambulance Service',
    aet: 'Emergency OPD Accidental Treatment',
    anes: 'Anesthetist fee',
    ath: 'Alternative treatement',
    cancer_chemo: 'Cancer chemo theraty',
    cancer_radio: 'Cancer radio therapy',
    chronic: 'Maintenance of non pre-exisitng chronic conditions',
    congenital: 'Congenital conditions',
    consult: 'Specialist consultation fee',
    dd: 'Deductible',
    dental: 'Dental treatment',
    dental_es: 'Dental treatment - Scaling and oral examination',
    dental_ex: 'Dental treatment - Filling, extraction and Root Canal',
    dental_x: 'Dental treatment - X-ray and Laboratory test',
    doct_fee: 'Doctor visit',
    er_oversea: 'International emergency medical assistance',
    fwp: 'Post-Hospital Surgery treatment after admission',
    ghe: 'Hospital General Expenses',
    hb: 'Hospital cash benefit',
    hc: 'Check up',
    hiv: 'HIV/AIDS',
    hnp: 'Hospice and palliative care lifetime',
    hormone: 'Hormone Replacement Therapy',
    hr: 'Hearing Care',
    icu: 'ICU room & board',
    lab: 'X-Ray, Lab Test',
    major_er: 'Major medical Emergency Outpatient',
    major_fwp: 'Major medical Post hospitalisation',
    major_med: 'Major medical Hospital general expenses',
    major_rb: 'Major medical Room and Board',
    major_sur: 'Major medical Surgical fee',
    med: 'Medication',
    med_home: 'Home medication',
    med_hosp: 'Hospital general expenses medication',
    med_oper: 'Operation medication',
    mt_c: 'Cesarean section',
    mt_m: 'Curettage',
    mt_nb: 'Normal Birth',
    mt_oper: 'Operation Medical supplies',
    mt1: 'Medical supplies 1',
    mt2: 'Medical supplies 2',
    mt3: 'Medical supplies 3',
    nba: 'NICU newborns',
    ns: 'Nursing fee',
    oct: 'Cancer treatment as an outpatient',
    okd: 'Dialysis as an outpatient',
    opd: 'OPD - lump sum',
    opd_v: 'OPD - per visit',
    oper: 'Operating Room and medical equipment',
    ot: 'Optical',
    other: 'Other hospital charges',
    ov: 'Over benefit',
    pa: 'Medical expense from accident',
    parent_rb: 'Parent accommodation',
    pct: 'Mental health treatement',
    pnc: 'Maternity',
    ppnc: 'Pre and post-natal complications',
    pre_ad: 'Pre Hospitalization before admission',
    psp: 'Physical therapy',
    px: 'Pre-existing conditions',
    r_b: 'Room & board per day ',
    sn: 'Special nursing',
    ss_sa: 'Surgical Non schedule',
    ss_ss: 'Surgical per schedule',
    tcm: 'Traditional chinese medicine',
    trc: 'Tubal resection',
    vc: 'Vaccination',
  },

  // * BATH
  THB: 'THB',

  // * ERROR PAGE
  errorPage: {
    title404: 'Page not found 404',
    title500: 'Sorry, our internal server error has occurred',
    desc500:
      'The server encountered a temporary error and could not complete your request. Please try again or return to main page.',
    title503: 'Service is currently unavailable',
    desc503: 'Please try again.',
    titleNoInternet: 'No internet connection',
    descNoInternet: 'Make sure Wi-Fi or cellular data is turned on. Then Try again.',
    ok: 'OK',
    backToHome: 'Return to Main Page',
    tryAgain: 'Try again',
  },

  // * Personal Information page
  profile: {
    MyAccount: 'My Account',
    PersonalInformation: 'Personal Information',
    Name: 'Name',
    Surname: 'Surname',
    Dob: 'Date of Birth',
    Contact: 'Contact',
    MobilePhone: 'Phone Number',
    Email: 'Email',
    AccountInformation: 'Account Information',
    BankAccount: 'Bank Account Name',
    AccountNumber: 'Bank Accounting Number',
    Tos: 'Terms of Service',
    Tac: 'Terms and Conditions',
    PrivacyPolicy: 'Privacy Policy',
    Back: 'Back',
  },

  // * Policy & Coverage page
  'Group Health Package': 'Group Health Package',
  'Policy Summary': 'Policy Summary',
  'Policy Status': 'Policy Status',
  'Policy Number': 'Policy Number',
  'Policy Name': 'Policy Name',
  'Member Name': 'Member Name',
  'Member Number': 'Member Number',
  'Dependent Number': 'Dependent Number',
  'Plan Number': 'Plan Number',
  'Start Date Coverage': 'Policy Effective Date',
  'End Date Coverage': 'Maturity Date',
  'You are currently viewing': 'You are currently viewing',
  'BACK TO TOP': 'BACK \n TO TOP',

  // * General
  'Claim Inquiry': 'Claim History',

  // * HEADER
  header: {
    claim: 'Claim History',
    claimSubmission: 'Submit Health Claim',
    addPolicy: 'Add Policy',
    claimDetail: 'Claim Detail',
    claimHistoryGroup: 'Claim History',
    claimHistory: 'Claim Status',
    claimHistoryDetail: 'Claim History Detail',
    claimSelectPolicyYear: 'Claim History',
    claimPolicyCoverage: 'Claim Policy Coverage',
    myInsurance: 'My Insurance Card',
    profile: 'My Account',
    policyCoverage: 'Group Health Package',
    fullYearCoverage: 'Group Health Package',
    policyMember: 'Group Health Package',
    documentDownload: 'Download Documents',
  },

  // * DOWNLOAD DOCUMENTS
  selectDocument: 'Select Document',
  downloadDocuments: 'Download Documents',
  claimForm: 'Claim Form',
  employeeManual: 'Employee Manual',
  otherForm: 'Other Form',

  // * Insurance Group
  'group-health-package': 'Group Health Package',
  'group-pa-package': 'Group PA Package',
  'group-credit-life': 'Group Credit Life',

  // * Insurance Services
  insuranceServices: 'Insurance Services',

  // * layout
  tel: 'tel.',
  downloadEmma: 'Download Emma by AXA',
  followUs: 'Follow us',
  copyRight: `COPYRIGHT © ${new Date().getFullYear()} KRUNGTHAI-AXA LIFE`,
  backButton: 'Back',

  // * Reminder
  policyOwner: 'Policy Owner',

  // * claim
  claimInquiryNotFoundTitle: 'Claim History Not Found',
  claimInquiryNotFoundDesc:
    'Sorry, this policy doesn’t have any related\nclaim list available at the moment.',
  pleaseCall1159: 'Please call 1159 for more information with the Customer Service Center staff.',
  underConsideration: 'Under consideration.',
};
