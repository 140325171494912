import ReactGA from 'react-ga4';

export const goBack = (navigate) => {
  ReactGA.event({
    category: 'Button Click',
    action: 'Navigate to previous page',
    label: 'Go back button',
  });

  navigate(-1);
};
