import { useTheme } from '@mui/material/styles';
//
import { StyledCircularProgress, RootStyle } from './Styled';

export default function LoadingScreen({ ...sx }) {
  const theme = useTheme();

  return (
    <RootStyle {...sx}>
      <svg width="40" height="40">
        <linearGradient id="linearColors" data-testid="linear-gradient">
          <stop offset="40%" stopColor={theme.palette.emma.$494DF4} />
          <stop offset="90%" stopColor={theme.palette.emma.$D9D9D9} />
        </linearGradient>
      </svg>
      <StyledCircularProgress thickness={4} />
    </RootStyle>
  );
}
