import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { getValueInSessionStorage } from '../../utils/cacheSession';
import { handleFormDataWithObject } from '../../utils/formData';
// routes
import { PATH_API } from '../../routes/paths';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  claimsAllTime: {},
  claims12Month: {},
  claim: null,
  benefit: null,
  policyList: null,
  groupClaimRules: null,
  submitResponse: null,
};

const slice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    // * START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // * HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // * GET CLAIMS All time
    getClaimsAllTimeSuccess(state, action) {
      state.isLoading = false;
      state.claimsAllTime = action.payload;
    },

    // * GET CLAIMS 12 MONTHs
    getClaims12MonthsSuccess(state, action) {
      state.isLoading = false;
      state.claims12Month = action.payload;
    },

    // * GET CLAIM ALL TIME
    getClaimSuccess(state, action) {
      state.isLoading = false;
      state.claim = {
        ...state.claims12Month?.detail?.filter((claim) => claim.notifyNo === action.payload)[0],
      };
    },

    // * GET CLAIM BENEFIT
    getClaimBenefitSuccess(state, action) {
      state.isLoading = false;
      state.benefit = action.payload;
    },

    getValidatePolicySuccess(state, action) {
      state.isLoading = false;
      state.policyList = action.payload;
    },

    // * GET VALIDATE POLICY
    getValidateGroupClaimRulesSuccess(state, action) {
      state.isLoading = false;
      state.groupClaimRules = action.payload;
    },
    // * GET VALIDATE POLICY
    submitClaimSuccess(state, action) {
      state.isLoading = false;
      state.submitResponse = action.payload;
    },

    // * CLEAR CLAIM STATE
    clearClaimState(state) {
      Object.assign(state, initialState);
    },
  },
});

// Reducer
export default slice.reducer;

export const { clearClaimState } = slice.actions;

// ----------------------------------------------------------------------

export function getClaimsAllTime(policyNo, certificate, familyNo) {
  return async () => {
    dispatch(slice.actions.startLoading());

    const { nationalID } = getValueInSessionStorage('userInfo');

    try {
      const claimResponse = await axios.post(PATH_API.claim.claimHistoryAllTime, {
        params: { policyNo, certificate, familyNo },
        nationalID,
        policyNo,
        certificate,
        familyNo,
      });

      dispatch(slice.actions.getClaimsAllTimeSuccess(claimResponse.data[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClaims12Months(policyNo, certificate, familyNo) {
  return async () => {
    dispatch(slice.actions.startLoading());

    const { nationalID } = getValueInSessionStorage('userInfo');

    try {
      const res = await axios.post(PATH_API.claim.claimHistory12Months, {
        nationalID,
        policyNo,
        certificate,
        familyNo,
      });

      dispatch(slice.actions.getClaims12MonthsSuccess(res.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClaim(notifyNo) {
  return () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.getClaimSuccess(notifyNo));
  };
}

export function getClaimBenefit(policyNo, certificate, familyNo) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const { nationalID } = getValueInSessionStorage('userInfo');

    try {
      const res = await axios.post(PATH_API.claim.claimBenefitAndBalance, {
        nationalID,
        policyNo,
        certificate,
        familyNo,
      });

      dispatch(slice.actions.getClaimBenefitSuccess(res.data[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getValidatePolicy(nationalID) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const res = await axios.post(PATH_API.claim.validateRules, {
        nationalID,
      });

      dispatch(slice.actions.getValidatePolicySuccess(res.data.data.policyList));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getValidateGroupClaimRules(policy, nationalID) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const res = await axios.post(PATH_API.claim.validateGroupClaimRules, {
        policyList: policy,
        nationalID,
      });

      dispatch(slice.actions.getValidateGroupClaimRulesSuccess(res.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

function handleUploadFile(resUploads) {
  const images = {
    bookBankImgs: [],
    medicalCertificateImgs: [],
    receiptImgs: [],
    slipImgs: [],
  };

  const fileMap = {};
  resUploads.uploads.forEach((image) => {
    const key = image.path;

    resUploads.len += image.size;

    if (!fileMap[key]) {
      images[image.fieldname].push(key);
      fileMap[key] = true;
    }
  });

  return images;
}

export function submitClaim(body, nationalID) {
  const formData = new FormData();
  const chunkSize = 1024 * 1024;
  const start = 0;
  const resUploads = { status: 200, uploads: [], len: 0 };

  const { receiptImgs, bookBankImgs, slipImgs, medicalCertificateImgs, attachedDocLen, ...other } =
    body;

  handleFormDataWithObject({ ...other, nationalID, attachedDocLen: attachedDocLen.curr }, formData);

  const readFileChunk = async (file, start, end, key, index) => {
    const blob = file.slice(start, end, file.type);

    formData.append(key, blob, `${key}_${index}.${file.type.split('/')[1]}`);

    axios
      .post(PATH_API.claim.uploads, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = (start + progressEvent.loaded) / file.size;
          console.log(`Progress: ${Math.round(progress * 100)}%`);
        },
      })
      .then(async (res) => {
        if (res.status === 200) {
          resUploads.status = res.status;
          resUploads.uploads = res.data;
        }

        if (end < file.size) {
          start = end;
          const nextChunk = Math.min(start + chunkSize, file.size);
          await readFileChunk(file, start, nextChunk, key, index);
        } else {
          console.log('File uploaded successfully');
        }
      })
      .catch((error) => {
        console.error('Error uploading file: ', error);
      });
  };

  function handleFormDataBlobArray(array, key) {
    array.forEach(async (file, index) => {
      await readFileChunk(file, start, Math.min(chunkSize, file.size), key, index);
    });
  }

  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      handleFormDataBlobArray(receiptImgs, 'receiptImgs');
      handleFormDataBlobArray(bookBankImgs, 'bookBankImgs');
      handleFormDataBlobArray(slipImgs, 'slipImgs');
      handleFormDataBlobArray(medicalCertificateImgs, 'medicalCertificateImgs');

      new Promise((resolve) => {
        const timer = setInterval(async () => {
          if (resUploads.status !== 200) {
            return;
          }

          const images = handleUploadFile(resUploads);

          if (resUploads.len < attachedDocLen.curr) {
            resUploads.len = 0;
            return;
          }

          console.log('resUploads.len => ', resUploads.len);
          console.log('attachedDocLen.curr => ', attachedDocLen.curr);

          clearInterval(timer);
          const data = { ...other, ...images };

          const response = await axios.post(PATH_API.claim.claimSubmit, data);

          dispatch(slice.actions.submitClaimSuccess(response.data));
          resolve(response.data);
        }, 1000);
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
