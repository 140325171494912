import { isNaN } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format('0,0');
}

function truncateToFixed(number, decimalPlaced) {
  const factor = 10 ** decimalPlaced;
  const truncatedNumber = Math.trunc(number * factor) / factor;

  return truncatedNumber.toFixed(decimalPlaced);
}

export function fCurrencyWithDigit(number) {
  numeral.zeroFormat('');
  numeral.nullFormat('');

  return numeral(truncateToFixed(parseFloat(number), 2)).format('0,0[.]00');
}

export function fCurrencyWithCents(number) {
  return numeral(number).format('0,0[.]00');
}

// Convert string "1,500" to number 1500
export function convertStringToNumber(input) {
  const cleanedInput = input?.replace(/,/g, '');

  const numberValue = parseFloat(cleanedInput);
  return isNaN(numberValue) ? null : numberValue;
}
