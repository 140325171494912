import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import palette from '../../theme/palette';
import { DrawerHeader } from './Styled';

export default function CustomDrawer({
  anchor = 'right',
  title,
  content,
  action,
  open,
  setOpen,
  toggleDrawer,
  width = 932,
  mode,
}) {
  return (
    <Drawer anchor={anchor} open={open} onClose={toggleDrawer}>
      <Box height="100%" width={width} role="presentation">
        {mode === 'menuSidebar' ? (
          <Box height="100%">{content}</Box>
        ) : (
          <>
            <DrawerHeader>
              <Typography variant="h3" color={palette.grey[900]}>
                {title}
              </Typography>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon sx={{ color: palette.primary.main }} />
              </IconButton>
            </DrawerHeader>
            <Box>{content}</Box>
            {action}
          </>
        )}
      </Box>
    </Drawer>
  );
}
CustomDrawer.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element.isRequired,
  action: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  anchor: PropTypes.string,
  width: PropTypes.number,
  mode: PropTypes.string,
};
