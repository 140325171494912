// @mui
import { IconButton } from '@mui/material';
// components
import Logo from '../components/Logo';
// hooks
import useResponsive from '../hooks/useResponsive';
//
import { ContainerLayoutHeader, SubHeaderToolbar } from './Styled';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function SubHeader() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <ContainerLayoutHeader>
      <SubHeaderToolbar>
        <IconButton
          sx={{
            paddingX: { xs: 3, md: 0 },
            paddingY: { xs: '0.5rem', md: 0 },
          }}
        >
          <Logo sx={{ width: '102px', height: '48px' }} />
        </IconButton>
        {isDesktop && (
          <MainHeader
            telStyle={{ order: 3, flexBasis: 'unset !important', padding: '0 1rem' }}
            divider2Style={{ order: 4 }}
            variantTel="h5Bold"
            width="100%"
            backgroundColor="common.white"
          />
        )}
      </SubHeaderToolbar>
    </ContainerLayoutHeader>
  );
}
