import { format } from 'date-fns';
//
import { monthNames } from './Model';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fTime(date, formatTime, options) {
  return format(new Date(date), formatTime, options);
}

export function convertMonthByLang(date) {
  if (!date) {
    return date;
  }

  const currentLang = localStorage.getItem('i18nextLng');

  const numericMonth = new Date(date).getMonth();

  return monthNames[currentLang][numericMonth];
}

function fYear(date) {
  return new Date(date).getFullYear();
}
export function convertYearByLang(date) {
  if (!date) {
    return date;
  }

  const currentLang = localStorage.getItem('i18nextLng');
  const year = fYear(date);

  return currentLang === 'en' ? year : year + 543;
}

export function convertDate(date, isConvertMonth = false, isConvertYear = true, separateBy = '/') {
  if (Number.isNaN(Date.parse(date))) {
    return date;
  }

  const convertedDate = fDate(date);
  const [day, month, year] = convertedDate.split('/');

  return `${day}${separateBy}${isConvertMonth ? convertMonthByLang(date) : month}${separateBy}${
    isConvertYear ? convertYearByLang(date) : year
  }`;
}

export function convertStringToDate(dateString = '') {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return `${year}/${month}/${day}`;
}

export function formatDateDob(date) {
  const currLang = localStorage.getItem('i18nextLng');
  const year = Number(String(date).substring(0, 4));
  const displayYear = currLang === 'en' ? year : year + 543;
  const month = String(date).substring(4, 6);
  const day = String(date).substring(6, 8);

  return `${day}/${month}/${displayYear}`;
}
