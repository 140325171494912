import * as React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@mui/material';
import { BootstrapDialog, CustomDialogTitle, CustomDialogContent, DialogTerms } from './Styled';

function BootstrapDialogTitle(props) {
  const { children, ...other } = props;

  return <CustomDialogTitle {...other}>{children}</CustomDialogTitle>;
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default function CustomDialogs({
  open,
  title,
  content,
  action,
  maxWidth,
  maxheight,
  custommaxwidth,
  handleClose,
  mode,
  scroll,
  sx,
}) {
  const descriptionElementRef = React.useRef(null);
  return (
    <>
      {mode === 'nodivide' ? (
        <DialogTerms
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth={maxWidth}
          maxheight={maxheight || '630px'}
          custommaxwidth={custommaxwidth}
        >
          <DialogTitle
            border={'none'}
            id="scroll-dialog-title"
            sx={{ paddingX: 0, paddingY: { xs: 2, md: 0 } }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            dividers={false}
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ paddingX: 2 }}
          >
            {content}
          </DialogContent>
          {action}
        </DialogTerms>
      ) : (
        <BootstrapDialog onClose={handleClose} open={open} fullWidth maxWidth={maxWidth} sx={sx}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            sx={{
              fontSize: {
                lg: 32,
                sm: 32,
                xs: 20,
              },
            }}
          >
            {title}
          </BootstrapDialogTitle>
          <CustomDialogContent
            sx={{
              fontSize: {
                lg: 20,
                sm: 20,
                xs: 16,
              },
            }}
          >
            {content}
          </CustomDialogContent>
          {action}
        </BootstrapDialog>
      )}
    </>
  );
}

CustomDialogs.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxheight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  custommaxwidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleClose: PropTypes.func,
  mode: PropTypes.string,
  scroll: PropTypes.string,
  sx: PropTypes.object,
};
