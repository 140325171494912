// ----------------------------------------------------------------------

function path(root, sublink = '', includeLang = false) {
  const currLang = localStorage.getItem('i18nextLng');
  const langPart = includeLang ? `/${currLang}` : '';

  return `${langPart}${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DATA_SERVICES = '/dataservices';
const ROOT_AUTH0 = '/auth0';
const ROOT_PROCESS_CLAIM = '/processClaims';

// ----------------------------------------------------------------------

// LOCAL PATHS
export const PATH_AUTH = {
  get login() {
    return path(ROOTS_AUTH, '/login');
  },
};

// ! Must be declare like this only
// ! Because to handle the multiple language
export const PATH_LOCAL = {
  // * ROOT AFTER LOGIN SUCCESSFUL
  get PATH_AFTER_LOGIN() {
    return path('/');
  },
  get claim() {
    return path('/claim');
  },
  get claimSubmission() {
    return path('/claim-submission');
  },
  get claimHistoryGroup() {
    return path('/claim', '/history-group');
  },
  get claimHistory() {
    return path('/claim', '/history');
  },
  get claimHistoryDetail() {
    return path('/claim', '/history/detail');
  },
  get claimSelectPolicyYear() {
    return path('/claim', '/policy');
  },
  get allPolicyPast() {
    return path('/policy', '/all-policy-past');
  },
  get selectPolicyYear() {
    return path('/policy', '/select-policy-year');
  },
  get myInsurance() {
    return path('/insurance');
  },
  get insuranceGroup() {
    return path('/insurance', '/group');
  },
  get insuranceECard() {
    return path('/insurance', '/detail');
  },
  get policyCoverage() {
    return path('/policy');
  },
  get fullYearCoverage() {
    return path('/policy', '/full-year-coverage');
  },
  get policyMember() {
    return path('/policy', '/member');
  },
  get profile() {
    return path('/profile');
  },
  get documentDownload() {
    return path('/download-documents');
  },
  get termsAndConditions() {
    return path('/terms-and-conditions');
  },
  // * Firewall is unavailable
  get page503() {
    return path('/503');
  },
  // * Internet is not working
  get pageNoInternet() {
    return path('/no-internet');
  },

  // * Error handling 500
  get page500() {
    return path('/500');
  },

  // * Error handling 404
  get page404() {
    return path('/404');
  },
};

export const PATH_EXTERNAL = {
  get downloadMyKTAXA() {
    return `https://www.krungthai-axa.co.th${path('/customer-service', '/emma-by-axa', true)}`;
  },
  get hospitalNetwork() {
    return `https://www.krungthai-axa.co.th${path('/customer-service', '/hospitals', true)}`;
  },
  get privacyNotice() {
    return `https://www.krungthai-axa.co.th${path(
      '/compliance',
      '/privacy-policy-candidates',
      true
    )}`;
  },
  get compliancePrivacyPolicy() {
    return `https://www.krungthai-axa.co.th${path('/compliance', '/privacy-policy', true)}`;
  },
  get privacyPolicy() {
    return `https://www.krungthai-axa.co.th${path('/Privacy-Policy', '', true)}`;
  },
  tel: 'tel://1159',
  social: {
    facebook: 'https://www.facebook.com/KrungthaiAXALife',
    line: 'https://page.line.me/krungthai-axalife?openQrModal=true',
    linkedin: 'https://www.linkedin.com/company/krungthai-axa-life-insurance/',
    youtube: 'https://www.youtube.com/user/KrungthaiAXA',
    ig: 'https://www.instagram.com/ktaxalife/',
    twitter: 'https://twitter.com/KTAXALife',
  },
  redirectToAxa:
    'https://ktaxav2.cdn.axa-contento-118412.eu/ktaxav2/4ea8c284-8c86-4555-949b-cd6da1b7d5c7_CL0000012-%E0%B9%83%E0%B8%9A%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%81%E0%B8%A3%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%84%E0%B9%88%E0%B8%B2%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B9%84%E0%B8%AB%E0%B8%A1%E0%B8%97%E0%B8%94%E0%B9%81%E0%B8%97%E0%B8%99%E0%B8%81%E0%B8%A3%E0%B8%93%E0%B8%B5%E0%B8%97%E0%B8%B8%E0%B8%9E%E0%B8%9E%E0%B8%A5%E0%B8%A0%E0%B8%B2%E0%B8%9E+%E0%B9%80%E0%B8%88%E0%B9%87%E0%B8%9A%E0%B8%9B%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B9%82%E0%B8%A3%E0%B8%84%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%A2%E0%B9%81%E0%B8%A3%E0%B8%87+%E0%B8%A3%E0%B8%B1%E0%B8%81%E0%B8%A9%E0%B8%B2%E0%B8%9E%E0%B8%A2%E0%B8%B2%E0%B8%9A%E0%B8%B2%E0%B8%A5+%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%84%E0%B9%88%E0%B8%B2%E0%B8%8A%E0%B8%94%E0%B9%80%E0%B8%8A%E0%B8%A2%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B8%B1%E0%B8%99+%28HLT1%29.pdf',
  redirectToAxaManual:
    'https://ktaxav2.cdn.axa-contento-118412.eu/ktaxav2/bd8d619f-e3c8-480d-b7f0-8fc093119482_%E0%B8%84%E0%B8%B9%E0%B9%88%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%AA%E0%B8%A7%E0%B8%B1%E0%B8%AA%E0%B8%94%E0%B8%B4%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9E%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%99%E0%B8%81%E0%B8%A5%E0%B8%B8%E0%B9%88%E0%B8%A1+%28TH%26EN%29+-Update+Aug+2022.pdf',
  get redirectToAxaOtherForm() {
    return `https://www.krungthai-axa.co.th${path(
      '/customer-service',
      '/download/general-form',
      true
    )}`;
  },
};

// ----------------------------------------------------------------------
// APIs PATHS
export const PATH_API = {
  auth: {
    verifyOTP: path(ROOT_AUTH0, '/verify-token'),
    getAuth0Token: path(ROOT_AUTH0, '/token'),
    verifyMobilePhone: path(ROOT_AUTH0, '/passwordless'),
    checkNationalId: path(ROOTS_AUTH, '/checkNationalId'),
  },
  user: {
    getProfile: '/user/employee-profile',
  },
  claim: {
    claimHistoryAllTime: path('/claimHistories', '/allTime/list'),
    claimHistory12Months: path('/claimHistories', '/latest12Months/list'),
    claimBenefitAndBalance: path('/claimBenefit', ''),
    rider: '/claimRider',
    validateRules: path('/validate', '/claim-rules'),
    validateGroupClaimRules: path(ROOT_PROCESS_CLAIM, '/validate/group-claim-rules'),
    claimSubmit: path(ROOT_PROCESS_CLAIM, '/submit'),
    uploads: path(ROOT_PROCESS_CLAIM, '/uploads'),
  },
  policy: {
    policiesList: path(ROOTS_DATA_SERVICES, '/policies/policylist'),
    policyDetail: path(ROOTS_DATA_SERVICES, '/policies/policydetail'),
    policyCoverage: path(ROOTS_DATA_SERVICES, '/policies/policycoverage'),
  },
  other: {
    healthCheck: '/health-check',
  },
};
