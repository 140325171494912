import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {
            borderRadius: 8,
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  };
}
