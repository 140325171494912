// routes
import { PATH_LOCAL } from '../../routes/paths';
//
import { ErrorPageNoBg } from './ErrorPage';

export default function PageNoInternet() {
  return (
    <ErrorPageNoBg
      title="errorPage.titleNoInternet"
      desc="errorPage.descNoInternet"
      redirectTo={PATH_LOCAL.PATH_AFTER_LOGIN}
    />
  );
}
