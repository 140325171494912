//
export function extractStringParts(inputString) {
  const parts = inputString.split('-');
  const policyNo = parts[0];
  const memberNo = parts[1];
  const dpntNo = parts[2];
  const policyStatus = parts[3];

  return { policyNo, memberNo, dpntNo, policyStatus };
}

// format date 2022-12-12 to 2022/12/12
export function formatDateDash(inputString) {
  const parts = inputString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parts[1];
  const day = parts[2];

  const thaiYear = year + 543;

  return `${day}/${month}/${thaiYear}`;
}

// format date "20221212" to 2022/12/12
export function formatDateString(inputString) {
  if (!inputString) return null;
  const currLang = localStorage.getItem('i18nextLng');
  const year = parseInt(inputString.substring(0, 4), 10);
  const displayYear = currLang === 'en' ? year : year + 543;
  const month = inputString.substring(4, 6);
  const day = inputString.substring(6, 8);

  return `${day}/${month}/${displayYear}`;
}

export function seperatePolicyDetail(arrayList) {
  if (!arrayList) return null;
  const basicPlan = arrayList.filter((item) => item.productPlanTypeEN === 'Basic plan');
  const additionalRider = arrayList.filter(
    (item) => item.productPlanTypeEN === 'Additional Rider (s)'
  );

  return {
    basicPlan,
    additionalRider,
  };
}

// find one policy by dpntNo
export function findDataByDpntNo(data, dpntNo) {
  return data?.find((item) => item.dpntNo === dpntNo);
}

export function andCondition(condition1, condition2) {
  if (!condition1 || !condition2) return null;
  return condition1 && condition2;
}

// format date dash in latestDataDate
export function formatLatestDataDate(dateString, currLang) {
  if (!dateString) return null;
  const datas = dateString.split('-');
  const day = datas[0];
  const month = datas[1];
  const year = Number(datas[2]);
  const displayYear = currLang === 'en' ? year : year + 543;

  return `${day}-${month}-${displayYear}`;
}
