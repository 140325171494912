import { Stack, styled, Button } from '@mui/material';

export const BaseStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '0',
  margin: '0',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
  },
}));

export const BackgroundImage = styled(Stack)(({ theme }) => ({
  width: '100vw',
  height: '100%',
  position: 'relative',
  '&::after': {
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    opacity: 0.9,
    background: `linear-gradient(152deg, ${theme.palette.emma.$494DF4} 0%, ${theme.palette.emma.$9FBEAF} 100%)`,
    backgroundSize: 'cover',
  },
}));

export const BackgroundImageIcon = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  bottom: 0,
  left: 0,
  right: 0,
  [theme.breakpoints.up('md')]: {
    margin: '0 auto',
  },
}));

export const OutlineButton = styled(Button)(() => ({
  width: '223px',
  height: '44px',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
}));

export const EmmaButton = styled(Button)(() => ({
  width: '223px',
  height: '44px',
  fontWeight: 700,
  fontSize: '21px',
  lineHeight: '21px',
}));
