import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// hooks
import useLocales from '../../hooks/useLocales';
//
import CustomDialogs from '../Dialog/Dialog';
import { ButtonAccept } from './Styled';

CancelConfirmClaimDialog.propTypes = {
  openDialogs: PropTypes.bool,
  setOpenDialogs: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default function CancelConfirmClaimDialog({ openDialogs, setOpenDialogs, handleCancel }) {
  const theme = useTheme();

  const { translate } = useLocales();

  return (
    <CustomDialogs
      open={openDialogs}
      handleClose={() => setOpenDialogs(false)}
      maxWidth={'xs'}
      mode="nodivide"
      custommaxwidth={'522px'}
      title={
        <Stack alignItems={'center'} color={theme.palette.grey[850]} paddingY={{ xs: 0, md: 4 }}>
          <Typography fontWeight={700} variant="h1Header" color="primary.text">
            {translate('claimSubmission.cancelTitle')}
          </Typography>
        </Stack>
      }
      content={
        <Stack
          alignItems={'center'}
          color={theme.palette.grey[850]}
          paddingBottom={{ xs: 3, md: 4.5 }}
        >
          <Typography
            variant="h5Text"
            fontFamily={theme.typography.fontFamilySecondary}
            fontWeight={500}
            whiteSpace={'nowrap'}
          >
            {translate('claimSubmission.cancelDesc1')}
          </Typography>
          <Typography
            variant="h5Text"
            fontFamily={theme.typography.fontFamilySecondary}
            fontWeight={500}
            whiteSpace={'nowrap'}
          >
            {translate('claimSubmission.cancelDesc2')}
          </Typography>
          <Typography
            variant="h5Text"
            fontFamily={theme.typography.fontFamilySecondary}
            fontWeight={500}
            whiteSpace={'nowrap'}
          >
            {translate('claimSubmission.cancelDesc3')}
          </Typography>
        </Stack>
      }
      action={
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={1}
          paddingX={{ xs: 9, md: 6 }}
          paddingBottom={4}
          fontFamily={theme.typography.fontFamilyTertiary}
        >
          <ButtonAccept variant="outlinedEmma" onClick={() => setOpenDialogs(false)}>
            <Typography fontWeight={700} variant="titleReg">
              {translate('claimSubmission.cancelContinue')}
            </Typography>
          </ButtonAccept>
          <ButtonAccept variant="primaryEmma" onClick={handleCancel}>
            <Typography fontWeight={700} variant="titleReg">
              {translate('claimSubmission.cancelButton')}
            </Typography>
          </ButtonAccept>
        </Stack>
      }
    />
  );
}
