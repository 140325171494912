import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const PRIMARY = {
  lighter: '#5C5CB7',
  light: '#3032C1',
  main: '#00008F',
  dark: '#1F1F9C',
  darker: '#00006D',
};

const SECONDARY = {
  lighter: '#E28972',
  light: '#DD7358',
  main: '#D24723',
  dark: '#D75D3D',
  darker: '#B03C1D',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#4976BA',
  dark: '#0C53B7',
  darker: '#04297A',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#138636',
  dark: '#229A16',
  darker: '#08660D',
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFBC11',
  dark: '#B78103',
  darker: '#7A4F01',
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#C91432',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const SPECIFIC_COLOR = {
  toolbar: '#FAFAFA',
  lightBlue: '#F8FAFC',
  brightCyan: '#00B2C5',
  pannikin: '#7698CB',
  blue1: '#DBE4F1',
  blueDarkIndigo: '#3B3FD8',
  green: '#1CC54E',
};

const EMMA_COLOR = {
  $494DF4: '#494DF4',
  $9FBEAF: '#9FBEAF',
  $B5D0EE: '#B5D0EE',
  $FAE6DE: '#FAE6DE',
  $F07662: '#F07662',
  $D9D9D9: '#D9D9D9',
};

const GREY = {
  0: '#FFFFFF',
  100: '#FAFAFA',
  200: '#F0F0F0',
  300: '#E5E5E5',
  400: '#CCCCCC',
  500: '#999999',
  600: '#757575',
  700: '#5F5F5F',
  800: '#343C3D',
  850: '#333333',
  900: '#111B1D',
  500_8: alpha('#919EAB', 0.08),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const COMMON = {
  common: { black: '#000', white: '#FFF', transparent: 'transparent' },
  primary: { ...PRIMARY, contrastText: '#FFF', text: '#333333' },
  secondary: { ...SECONDARY, contrastText: '#FFF', text: '#5F5F5F' },
  info: { ...INFO, contrastText: '#FFF' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastTest: '#FFF' },
  grey: GREY,
  divider: GREY[500_24],
  action: {
    hover: GREY[200],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[200],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
    placeholder: GREY[600],
  },
};

const palette = {
  ...COMMON,
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[400] },
  background: {
    paper: '#FFF',
    default: '#FFF',
    main: GREY[600],
    neutral: GREY[200],
    cardDetail: '#F5F5F5',
  },
  action: { active: GREY[600], ...COMMON.action },
  specific: { ...SPECIFIC_COLOR },
  emma: { ...EMMA_COLOR },
};

export default palette;
