// ----------------------------------------------------------------------

const HOST_ENDPOINT = process.env.REACT_APP_HOST_ENDPOINT || '';
const CURRENT_ORIGIN = process.env.REACT_APP_CURRENT_ORIGIN;

const ALL_LANGUAGE = ['en', 'th'];

// * DEFAULT MAXIMUM LENGTH TO ATTACHED FILES -> CLAIM SUBMISSION
// * 5MB
const MAX_ATTACH_FILES = 1024 * 1024 * 5;

// SECRET KEY
const SESSION_SECRET_KEY = process.env.REACT_APP_SESSION_SECRET_KEY;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const FILE_EXTENSION_REG_EXP = process.env.REACT_APP_FILE_EXTENSION_REG_EXP;
const ADD_COMMA_REG_EXP = process.env.REACT_APP_ADD_COMMA_REG_EXP;
const REMOVE_NON_NUMERIC_REG_EXP = process.env.REACT_APP_REMOVE_NON_NUMERIC_REG_EXP;

// DEFAULT LANGUAGE
const DEFAULT_LANGUAGE = ALL_LANGUAGE[1];
const SHORT_LANG = {
  th: 'ไทย',
  en: 'EN',
};
const LONG_LANG = {
  en: 'ENGLISH',
  th: 'ภาษาไทย',
};

// ALLOWS SECURITY
const helmetOptions = {
  contentSecurityPolicy: {
    useDefaults: false,
    directives: {
      defaultSrc: ["'self'"],
      scriptSrc: ["'self'"],
      objectSrc: ["'none'"],
      upgradeInsecureRequests: ["'self'"],
      frameAncestors: ["'none'"], // Prevent embedding in iframes
    },
    reportOnly: true,
  },
  xFrameOptions: {
    action: 'deny', // not allow to displayed in iframes on other websites
  },
  noSniff: true,
  xssFilter: true,
  hidePoweredBy: true,
  ieNoOpen: true,
};

export {
  GA_MEASUREMENT_ID,
  helmetOptions,
  FILE_EXTENSION_REG_EXP,
  ADD_COMMA_REG_EXP,
  REMOVE_NON_NUMERIC_REG_EXP,
  HOST_ENDPOINT,
  CURRENT_ORIGIN,
  ALL_LANGUAGE,
  DEFAULT_LANGUAGE,
  SHORT_LANG,
  LONG_LANG,
  SESSION_SECRET_KEY,
  MAX_ATTACH_FILES,
};
