export function pxToRem(value) {
  return `${value / 16}rem`;
}

// * ms-> mobile S-> 320px
export function responsiveFontSizes({ xs, ms, sm, md, lg }) {
  return {
    '@media (min-width:300px)': {
      fontSize: pxToRem(xs),
    },
    '@media (min-width:375px)': {
      fontSize: pxToRem(ms),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}
