import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { Stack, Typography } from '@mui/material';
// components
import Image from '../../components/Image/Image';
// hooks
import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
// routes
import { PATH_LOCAL } from '../../routes/paths';
//
import {
  BackgroundImage,
  BackgroundImageIcon,
  BaseStack,
  EmmaButton,
  OutlineButton,
} from './Styled';

ErrorPageNoBg.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  redirectTo: PropTypes.string,
};

export function ErrorPageNoBg({ title, desc, redirectTo }) {
  // hooks
  const isDesktop = useResponsive('up', 'md');
  const { translate } = useLocales();

  const navigate = useNavigate();

  const gaTracker = useAnalyticsEventTracker('Button click');

  return (
    <BaseStack>
      <Stack
        zIndex={1}
        justifyContent={'center'}
        alignItems={'center'}
        overflow={'hidden'}
        height={'100%'}
        spacing={{ xs: 17.875, md: 20.625 }}
      >
        <Stack spacing={3} marginTop={7.5} maxWidth={'595px'} paddingX={3} alignItems={'center'}>
          <Typography
            textAlign={'center'}
            variant={isDesktop ? 'h1Bold' : 'h2Reg'}
            color="primary.text"
          >
            {translate(title)}
          </Typography>
          <Typography textAlign={'center'} variant="body2Reg" color="grey.800" whiteSpace="pre">
            {translate(desc)}
          </Typography>
        </Stack>
        <EmmaButton
          onClick={() => {
            gaTracker('Navigate to main page from error page', 'Ok button');
            navigate(redirectTo);
          }}
          variant="primaryEmma"
          sx={{ textTransform: 'unset', marginBottom: { xs: 6, md: 0 } }}
        >
          {translate('errorPage.tryAgain')}
        </EmmaButton>
      </Stack>
    </BaseStack>
  );
}

ErrorPageWithBg.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  buttonText: PropTypes.string,
  src: PropTypes.string,
};

export function ErrorPageWithBg({ title, desc, buttonText, src }) {
  // react-router
  const navigate = useNavigate();
  // hooks
  const isDesktop = useResponsive('up', 'md');
  const { translate } = useLocales();
  const gaTracker = useAnalyticsEventTracker('Button click');

  return (
    <BackgroundImage>
      <Stack
        height="100%"
        justifyContent="center"
        alignItems={'center'}
        overflow={'hidden'}
        zIndex={1}
        mt={{ xs: 8.625, md: 0 }}
      >
        <Image src={src} />
        <Stack marginTop={6} maxWidth={'595px'} paddingX={3} alignItems={'center'}>
          <Typography textAlign={'center'} variant={isDesktop ? 'h1Bold' : 'h2Reg'} color="grey.0">
            {translate(title)}
          </Typography>
          {desc && (
            <Typography textAlign={'center'} variant="body2Reg" color="grey.0">
              {translate(desc)}
            </Typography>
          )}
        </Stack>
        <Stack marginTop={6} mb={{ xs: 8.625, md: 0 }}>
          <OutlineButton
            onClick={() => {
              gaTracker('Navigate to main page from error page', 'Try again button');
              navigate(PATH_LOCAL.PATH_AFTER_LOGIN);
            }}
            variant="outlinedWhite"
          >
            {translate(buttonText)}
          </OutlineButton>
        </Stack>
      </Stack>
      <BackgroundImageIcon>
        <Image
          src={isDesktop ? '/assets/background_error.svg' : '/assets/background_error_mobile.svg'}
          sx={{ flexBasis: '100%' }}
          height="100%"
          width="100%"
          objectPosition={{ xs: '0px -15px', md: '55% 30%' }}
        />
      </BackgroundImageIcon>
    </BackgroundImage>
  );
}
