import PropTypes from 'prop-types';
// @mui
import { Link, ListItem } from '@mui/material';
// components
import Image from '../components/Image/Image';
//
import { ListItemIconStyle } from './Styled';

FooterItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.any,
    to: PropTypes.string.isRequired,
  }),
};

export default function FooterItem({ item, ...other }) {
  const { icon, to } = item;

  return (
    <ListItem sx={{ ...other }}>
      <Link
        href={to}
        rel="noopener"
        target="_blank"
        underline="none"
        width={{ xs: '16.55px', md: '1.5rem' }}
        height={{ xs: '16.55px', md: '1.5rem' }}
      >
        <ListItemIconStyle>
          <Image src={icon} />
        </ListItemIconStyle>
      </Link>
    </ListItem>
  );
}
