import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// redux
import { useSelector } from '../redux/store';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
// routes
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, logout, user } = useAuth();

  const { error: policyError } = useSelector((state) => state.policy);
  const { error: claimError } = useSelector((state) => state.claim);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (policyError?.message || claimError?.message) {
    const errorList = [policyError?.message, claimError?.message];
    const regEx = /authorization/i;

    const isAuthError = errorList.some((error) => regEx.test(error));

    if (isAuthError) {
      sessionStorage.setItem('isAuthError', true);
    }
  }

  if (sessionStorage.getItem('isAuthError')) {
    setTimeout(() => {
      logout();
    }, 500);
  }

  if (!isAuthenticated || !user) {
    return <Navigate to={PATH_AUTH.login} replace />;
  }

  return <>{children}</>;
}
