import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
import useAuth from '../hooks/useAuth';
import useToggle from '../hooks/useToggle';
// constants
import { errorPageList } from '../constants/general';
// utils
import { handleResponsive } from '../utils/handleTheme';
//
import { ContainerLayoutStyle, DividerLayoutStyle, RootLayoutStyle } from './Styled';
import MainHeader from './MainHeader';
import SubHeader from './SubHeader';
import Footer from './Footer';
import MobileMainHeader from './MobileMainHeader';
import MenuSidebar from './MenuSidebar';
import { handleCheckErrorPage, handleOpenDrawer, renderRedirectController } from './events';
import RedirectController from '../components/UI/RedirectController';
import { handleEnableScroll } from './Model';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const isDesktop = useResponsive('up', 'md');

  const {
    toggle: openDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
    setToggle: setToggleDrawer,
  } = useToggle();

  const { user } = useAuth();

  const { pathname } = useLocation();

  const { productHeader } = useParams();

  return (
    <RootLayoutStyle
      id="container-root-content"
      enableScroll={handleEnableScroll(pathname, productHeader)}
    >
      {/* main header */}
      {(isDesktop || !user || errorPageList.includes(pathname)) && (
        <>
          {!isDesktop && (
            <>
              <DividerLayoutStyle variant="fullWidth" ownerState={{ status: 'header' }} />
              <DividerLayoutStyle />
              <MainHeader variantTel="textButton" />
            </>
          )}
          <SubHeader />
          <DividerLayoutStyle />
        </>
      )}

      {/* mobile header */}
      {!isDesktop && user && !errorPageList.includes(pathname) && (
        <MobileMainHeader
          onOpen={() => handleOpenDrawer(onOpenDrawer)}
          memberName={`${user?.firstname} ${user?.lastname}`}
        />
      )}

      {/* container outlet */}
      <ContainerLayoutStyle fullWidth={errorPageList.includes(pathname)}>
        <Stack direction="column" flex={1} sx={{ overflowY: { xs: 'unset', md: 'auto' } }}>
          <Stack
            direction="row"
            flexWrap={handleResponsive(!isDesktop, 'wrap', 'nowrap')}
            flex={1}
            sx={{ overflowY: { xs: 'unset', md: 'auto' } }}
          >
            {user && !handleCheckErrorPage(pathname) && (
              <MenuSidebar
                openDrawer={openDrawer}
                setToggleDrawer={setToggleDrawer}
                onCloseDrawer={onCloseDrawer}
              />
            )}
            {handleResponsive(
              renderRedirectController(isDesktop, pathname, user),
              <Outlet />,
              <Box id="main-container" width="100%" sx={{ overflowY: { xs: 'unset', md: 'auto' } }}>
                <Outlet />
                <RedirectController />
              </Box>
            )}
          </Stack>
        </Stack>
      </ContainerLayoutStyle>

      {/* footer */}
      {(!user || errorPageList.includes(pathname)) && <Footer />}
    </RootLayoutStyle>
  );
}
