import { useTranslation } from 'react-i18next';
// config
import { ALL_LANGUAGE, DEFAULT_LANGUAGE } from '../config';
//
import useAnalyticsEventTracker from './useAnalyticsEventTracker';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const currentLang = ALL_LANGUAGE.find((lang) => lang === i18n.language) || DEFAULT_LANGUAGE;

  const gaEventTracker = useAnalyticsEventTracker('Button click');

  const handleChangeLanguage = (newLang) => {
    gaEventTracker('Change language', 'Change language button');
    i18n.changeLanguage(newLang);
  };

  return {
    translate: (text, options) => translate(text, options),
    currentLang,
    allLangs: ALL_LANGUAGE,
    changeLanguage: (newLang) => handleChangeLanguage(newLang),
  };
}
