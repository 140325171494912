import ReactGA from 'react-ga4';
// utils
import { getValueInSessionStorage, setValueInSessionStorage } from '../../utils/cacheSession';
import { convertTitleToParamCase } from '../../utils/changeCase';
// routes
import { PATH_LOCAL } from '../../routes/paths';
// constants
import { FAMILY_OWNER } from '../../constants/general';
// functional
import { extractStringParts } from '../../pages/policy/events';
import { handlePathHistoryGroup } from '../../pages/claim/events';
import { pageName, includeReminderList } from './Model';

export function handleClickNavigate(currentPath, policyNo, memberId, productHeader) {
  ReactGA.event({
    category: 'Button click',
    action: 'Navigate to full year coverage page',
    label: 'Benefit and coverage family button',
  });

  return currentPath
    .replace('/policy/', `/policy/${productHeader}/${policyNo}/`)
    .replace('/member', `/member/${memberId}`);
}

export const handleToTop = (cscroll, wrapperContent) => {
  ReactGA.event({
    category: 'Button click',
    action: 'Go to top screen',
    label: 'to top button',
  });

  window.scrollTo(0, 0);
  cscroll.scroll({ top: 0, behavior: 'smooth' });
  wrapperContent.scroll({ top: 0, behavior: 'smooth' });
};

// add user's firstname in to family list
export function updateFamilyListData(userOwner, familyList, translate) {
  const resFamilyList = {
    familyClient: translate('policyOwner'),
    firstname: userOwner.firstname,
    lastname: userOwner.lastname,
    dpntNo: FAMILY_OWNER,
  };

  let updatedFamilyList;

  if (Array.isArray(familyList)) {
    updatedFamilyList = [...familyList];
  } else {
    updatedFamilyList = [];
  }
  updatedFamilyList.push(resFamilyList);
  setValueInSessionStorage('updatedFamilyList', updatedFamilyList);

  return updatedFamilyList;
}
export function isExternalLink(path) {
  return path.includes('http');
}

export function selectPolicyClick(policy, pathname) {
  const path = pathname.includes('claim') ? 'claim' : 'policy';

  const lookup = {
    claim: handlePathHistoryGroup(policy),
    policy: `${PATH_LOCAL.policyCoverage}/${convertTitleToParamCase(
      policy.productHeaderEN,
      true
    )}/${policy?.policyNo}-${policy?.memberNo}-${policy?.dpntNo}-${policy?.policyStatus}`,
  };

  ReactGA.event({
    category: 'Button click',
    action: `Navigate to ${pageName[path]} page`,
    label: 'Active policy',
  });

  return lookup[path];
}

export function navigateToAllPolicyPast(linkToAllPolicyPast) {
  ReactGA.event({
    category: 'Button click',
    action: 'Navigate to All past 1 year policy page',
    label: 'Inactive policy',
  });

  return linkToAllPolicyPast;
}

export function findNameByDpntNo(memberId, familyNo, selectedFamily, params, familyList) {
  const { dpntNo } = params?.includes('-')
    ? extractStringParts(params)
    : { dpntNo: selectedFamily || familyNo };

  const currentMember = familyList.find((item) => item.dpntNo === (memberId ?? dpntNo));

  if (currentMember) {
    return {
      firstname: currentMember.firstname,
      lastname: currentMember.lastname,
    };
  }
  const userInfo = getValueInSessionStorage('userInfo');
  return {
    firstname: userInfo?.firstname,
    lastname: userInfo?.lastname,
  };
}

export function checkScrollPosition(cscroll, wrapperContent, setShowScroll) {
  if (!cscroll || !wrapperContent) {
    return;
  }

  const handleScroll = (scrollPosition) => {
    if (scrollPosition >= 100) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  cscroll.addEventListener('scroll', () => handleScroll(cscroll.scrollTop));
  wrapperContent.addEventListener('scroll', () => handleScroll(wrapperContent.scrollTop));
}

export function handleShowFamilyList(setShowData, showData, cscroll) {
  ReactGA.event({
    category: 'Button click',
    action: 'Toggle show family list',
    label: `Reminder tab`,
  });

  setShowData(!showData);
  cscroll.style.overflowY = !showData ? 'hidden' : 'auto';
}

function familyClick(dpntNo, params, navigate, currPolicy) {
  const pathname = window.location.href;
  const { dpntNo: dpntNoParam } = extractStringParts(params);
  if (pathname.includes('full-year-coverage')) {
    const newParam = params.replace(`-${dpntNoParam}-`, `-${dpntNo}-`);
    const newCurrPolicy = pathname.replace(params, newParam);
    window.location.href = newCurrPolicy;
  } else if (dpntNoParam !== dpntNo) {
    navigate(
      `${PATH_LOCAL.policyCoverage}/${convertTitleToParamCase(currPolicy.productHeaderEN, true)}/${
        currPolicy.policyNo
      }-${currPolicy.memberNo}-${currPolicy.dpntNo}-${currPolicy.policyStatus}/member/${dpntNo}`
    );
  } else {
    navigate(
      `${PATH_LOCAL.policyCoverage}/${convertTitleToParamCase(currPolicy.productHeaderEN, true)}/${
        currPolicy.policyNo
      }-${currPolicy.memberNo}-${currPolicy.dpntNo}-${currPolicy.policyStatus}`
    );
  }
}

export function handleSelectFamily(handleSelectedFamily, item, params, navigate, currPolicy) {
  if (handleSelectedFamily) {
    handleSelectedFamily(item.dpntNo);
    return;
  }

  familyClick(item.dpntNo, params, navigate, currPolicy);
}

export function truncatedPolicyNameSeperate(isDesktop, policyName, maxPolicyNameLength) {
  if (!isDesktop && policyName?.length > maxPolicyNameLength) {
    return `${policyName.slice(0, 15)}...`;
  }
  return policyName;
}

export function handleExpand() {
  const { pathname } = window.location;

  return includeReminderList.some((path) => pathname.includes(path));
}