import { AES, enc } from 'crypto-js';
import { isEmpty } from 'lodash';
import { SESSION_SECRET_KEY } from '../config';

export function getValueInSessionStorage(key) {
  const valueInSessionStorage = sessionStorage.getItem(key);

  if (!isEmpty(valueInSessionStorage)) {
    const decrypted = AES.decrypt(valueInSessionStorage, SESSION_SECRET_KEY).toString(enc.Utf8);

    if (isEmpty(decrypted)) {
      return null;
    }

    const obj = JSON.parse(decrypted);
    return typeof obj === 'string' ? obj.replace(/^"(.*)"$/, '$1') : obj;
  }

  return null;
}

export function setValueInSessionStorage(key, data) {
  const cipherText = AES.encrypt(JSON.stringify(data), SESSION_SECRET_KEY);

  sessionStorage.setItem(key, cipherText);
}
