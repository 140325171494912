import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderBanner = styled(Stack)(({ theme }) => ({
  height: '60px',
  color: theme.palette.grey[0],
  background: `linear-gradient(to right, ${theme.palette.emma.$494DF4}, ${theme.palette.emma.$9FBEAF})`,
  filter: `drop-shadow(${theme.customShadows.subShadow})`,
  justifyContent: 'center',
  padding: '0 1.5rem 0 1.375rem',
  '@media screen and (orientation: landscape)': {
    height: '44px',
    padding: 0,
    justifyContent: 'center',
  },
}));

export const HeaderContent = styled(Stack)(() => ({
  height: '60px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 0',
}));
